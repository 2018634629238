import { Button, Grid, TextField, Typography, styled } from '@mui/material';
import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CallIcon from '@mui/icons-material/Call';
import { useNavigate } from 'react-router-dom';

type Props = {}
const FooterComponent = styled("footer")(({ theme }) => ({
  backgroundColor: "#436336",
}));
const FooterContainer = styled(Grid)(({ theme }) => ({
  width: '90%',
  maxWidth: "1440px",
  padding: "20px 0",
  margin: "0 auto",
  justifyContent: "space-between",
  flexWrap: "wrap-reverse"

}));
const Text90PX = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '50px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '60px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '70px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '80px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '90px'
  },
}));
const Text20PX = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  fontWeight: "600",
  [theme.breakpoints.up("xs")]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '18px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '20px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '22px'
  },
}));
const Text28PX = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  fontWeight: "600",
  [theme.breakpoints.up("xs")]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '20px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '24px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '28px'
  },
}));
const LayoutLeft = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    Width: '48%',
  },
  flexShrink: "0",
}));
const LayoutRight = styled(Grid)(({ theme }) => ({
  flexShrink: "0",
  alignItems: "self-end",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    Width: '48%',
  },
}));
const ButtonLink = styled(Button)(({ theme }) => ({
  color: "#FFF",
  fontWeight: "600",
  [theme.breakpoints.up("xs")]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '18px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '20px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '22px'
  },
  borderRadius: "24px",
  border: "1px solid #FFF",
  width: "160px",
  padding: "6px 0"
}));
function Footer({ }: Props) {
  const navigate = useNavigate()
  return (
    <FooterComponent>
      <FooterContainer container>
        <LayoutLeft >
          <Text90PX>
            LOGO
          </Text90PX>
          <Text20PX margin={"12px 0"}>
            บริษัท อัฟรอซ จำกัด <br />
            1769 ซอยพัฒนาการ37 ถนนพัฒนาการ <br />
            แขวงพัฒนาการเขตสวนหลวง กรุงเทพ 10250
          </Text20PX>
          <Grid container margin={"12px 0"} sx={{ color: "#FFF" }}>
            <CallIcon />
            <Text20PX>
              +66 62-953-2878
            </Text20PX>
          </Grid>
          <Grid container margin={"12px 0"} sx={{ color: "#FFF" }} gap={"24px"}>
            <FacebookIcon />
            <EmailIcon />
            <InstagramIcon />
            <YouTubeIcon />
            <CallIcon />
          </Grid>
        </LayoutLeft>
        <LayoutRight >
          <Grid container margin={"12px 0"} gap={"12px"} marginTop={"32px"} sx={{ display: { xs: "none", md: "flex" } }}>
            <ButtonLink variant="outlined" onClick={() => navigate("/shop")}>
              SHOP
            </ButtonLink>
            <ButtonLink variant="outlined" onClick={() => navigate("/contactus")}>
              CONTACT US
            </ButtonLink>
            <ButtonLink variant="outlined" onClick={() => navigate("/aboutus")}>
              ABOUT US
            </ButtonLink>
          </Grid>
          <Grid>
            <Text28PX margin={"12px 0"} textAlign={{ xs: "start", md: "end" }}>
              SING UP FOR THE NEWSLETTER
            </Text28PX>
            <Text20PX margin={"12px 0"} textAlign={{ xs: "start", md: "end" }}>
              Subscribe for the latest stories and promotions
            </Text20PX>
            <Grid container margin={"12px 0"} justifyContent={{ xs: "start", md: "end" }}>
              <TextField
                sx={{ border:"1px solid #FFF"}}
                placeholder='Enter your e-mail address'
                variant="outlined" />
            </Grid>
          </Grid>
        </LayoutRight>
      </FooterContainer>
    </FooterComponent>
  )
}

export default Footer