import { Badge, Divider, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, styled } from '@mui/material';
import React, { useState } from 'react';
import Logo from '../../assets/logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Hamburger from 'hamburger-react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CallIcon from '@mui/icons-material/Call';
import Cookies from 'universal-cookie';

type Props = {}
const NavbarComponent = styled(Grid)(({ theme }) => ({
    backgroundColor: "#AEC782",
    justifyContent: "center",
    alignItems: "center",
    height: "90px",
    position: "relative"
}));
const Navbarcontainer = styled(Grid)(({ theme }) => ({
    Width: '95%',
    justifyContent: "space-between",
    maxWidth: "1440px",
    flexWrap: "nowrap",
    alignItems: "center"
}));
const IMG = styled("img")(({ theme }) => ({
    height: "89px"
}));
const NavbarListsMenu = styled(Grid)(({ theme }) => ({
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
        justifyContent: "end",
        width: "30%",

    },
    [theme.breakpoints.up("sm")]: {
        justifyContent: "space-around",
        width: "80%",

    }
}));
const NavbarNavigationMenuIcon = styled(Grid)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "end",

    },
    [theme.breakpoints.down("md")]: {
        width: "20%",

    },
    [theme.breakpoints.up("md")]: {
        width: "40%",

    },
    flexWrap: "nowrap"
}));
const InputSearch = styled(OutlinedInput)(({ theme }) => ({
    borderRadius: "20px",
    backgroundColor: "#FFF",
    width: "100%"
}));
const LinkMenu = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    [theme.breakpoints.down("sm")]: {
        display: "none"
    },
    [theme.breakpoints.up("md")]: {
        display: "block"
    }
}));
const NavbarComponentMoble = styled(Grid)(({ theme }) => ({
    backgroundColor: "#AEC782",
    height: "380px",
    position: "absolute",
    top: "-90",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
        display: "flex"
    },
    [theme.breakpoints.up("sm")]: {
        display: "none"
    },
    zIndex:"9"
}));
const LinkMenuList = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    fontSize: "18px",
    marginTop: "12px"
}));
const RowContainer = styled(Grid)(({ theme }) => ({
    width: "90%",
    margin: "6px auto",
    justifyContent: "space-between",
}));
const IconContainer = styled(Grid)(({ theme }) => ({
    width: "70%",
    margin: "6px auto",
    justifyContent: "space-between",
    color: "#FFF",
    marginTop: "24px"
}));
const Line = styled(Divider)(({ theme }) => ({
    margin: "6px 0",
    borderColor: "#EFEFEF3B",
    borderWidth: "1px"
}));
function Navbar({ }: Props) {
    const LinkLists = [
        {
            path: "/",
            pagename: "หน้าแรก"
        },
        {
            path: "/shop",
            pagename: "สินค้า"
        },
        {
            path: "/news",
            pagename: "NEWS"
        },
        {
            path: "/aboutus",
            pagename: "ABOUT US"
        },
        {
            path: "/contactus",
            pagename: "CONTACT US"
        },
    ]
    const Navigate = useNavigate()
    const { pathname } = useLocation()
    const [isOpen, setOpen] = useState(false)
    const cookies = new Cookies(null, { path: '/' });

    const redirect = () => {

        if (cookies.get('token') != null && cookies.get('token') !== '' && cookies.get('token') !== undefined) {
            return Navigate('/edit');
        } else {
            return Navigate('/login');
        }
        
    }
    return (
        <>
            <NavbarComponent container>
                <Navbarcontainer container>
                    {
                        !isOpen ? <IMG src={Logo} onClick={()=>Navigate("/")} /> : (
                            <FormControl
                                sx={{
                                    width: '65%',
                                    marginLeft: "6px"
                                }}
                                variant="outlined">
                                <InputSearch
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>}
                                />
                            </FormControl>
                        )
                    }
                    <NavbarListsMenu container>
                        {
                            LinkLists.map((menu, idx) => <LinkMenu sx={{ color: pathname === menu.path ? "#000" : "#FFF" }} key={idx} to={menu.path}>{menu.pagename}</LinkMenu>)
                        }
                        <NavbarNavigationMenuIcon container>
                            <FormControl
                                sx={{
                                    width: '70%',
                                    display: {
                                        xs: "none",
                                        md: "block"
                                    }
                                }}
                                variant="outlined">
                                <InputSearch
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>}
                                />
                            </FormControl>
                            <IconButton onClick={()=> redirect()}>
                                <PersonIcon sx={{ color: "#FFF", fontSize: "32px" }} />
                            </IconButton>
                            <IconButton onClick={()=>Navigate("/cart")}>
                                <Badge badgeContent={5} color="error">
                                    <ShoppingCartIcon sx={{ color: "#FFF", fontSize: "32px" }} />
                                </Badge>
                            </IconButton>
                        </NavbarNavigationMenuIcon>
                        <IconButton sx={{
                            display: {
                                xs: "block",
                                sm: "none"
                            }
                        }}>
                            <Hamburger color='#fff' size={32} toggled={isOpen} toggle={setOpen} />
                        </IconButton>
                    </NavbarListsMenu>
                </Navbarcontainer>
            </NavbarComponent >
            {
                isOpen ? (
                    <NavbarComponentMoble container>
                        {
                            LinkLists.map((menu, idx) => (
                                <>
                                    <RowContainer container onClick={()=>setOpen(!isOpen)}>
                                        <LinkMenuList sx={{ color: pathname === menu.path ? "#000" : "#FFF" }}
                                            key={idx} to={menu.path}>
                                            {menu.pagename}
                                        </LinkMenuList>
                                        <KeyboardArrowRightIcon />
                                    </RowContainer>
                                    <Line />
                                </>
                            ))
                        }
                        <IconContainer container>
                            <FacebookIcon />
                            <EmailIcon />
                            <InstagramIcon />
                            <YouTubeIcon />
                            <CallIcon />
                        </IconContainer>
                    </NavbarComponentMoble>
                ) : (<></>)
            }

        </>

    )
}

export default Navbar