import { AccountCircle, CloudUploadOutlined, DeleteOutline, EditOutlined, SearchOutlined } from "@mui/icons-material"
import { Avatar, Box, Button, FormControl, FormLabel, IconButton, Input, InputAdornment, InputBase, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Paper, Stack, Tab, Tabs, TextField, Typography } from "@mui/material"
import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'universal-cookie';

const Banner = () => {

    const [fileImg, setFileImg] = useState<any>(undefined);
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();

    const handleUpload = (event: any) => {
        if(event.target.files[0] === undefined) {
            return;
        }

        setFileImg(event.target.files[0]);
    };

    const uploadBanner = () => {

        if(fileImg !== undefined) {
            const formData = new FormData();
            formData.append('file', fileImg);
            
            axios.post(`${process.env.REACT_APP_API_URL}/banner/upload`, formData, {
                headers: {
                    token: cookies.get('token')
                }
            }).then((response) => {
                if(response.data.message == 'OK')
                {
                    Swal.fire({
                        title: "อัพโหลดรูปภาพเรียบร้อยแล้ว",
                        icon: "success",
                    })

                }
                else
                {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: `อัพโหลดรูปภาพไม่สำเร็จ (${response.data.message})`,
                        icon: "error",
                    })
                }
            })
        } else {
            Swal.fire({
                title: "กรุณาเลือกรูปภาพ Banner",
                icon: "error",
            })
        }
        
    }

    useEffect(() => {
        if (cookies.get('token') == null || cookies.get('token') === '' || cookies.get('token') === undefined) {
            return navigate('/');
        }

        axios.get(`${process.env.REACT_APP_API_URL}/token/check`, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {

            if(response.data.isAdmin !== 1) {
                return navigate('/');
            }
        })
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item md={6}>
                <Stack direction="row" alignItems="center">
                    <input
                        accept="image/*"
                        id="upload-photo"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleUpload}
                    />
                    <label htmlFor="upload-photo">
                        <Button
                            variant="contained"
                            component="span"
                            startIcon={<CloudUploadOutlined />}
                        >
                            Upload Photo
                        </Button>
                    </label>

                    <Typography variant="body2" color="textSecondary">
                        &nbsp;&nbsp;Maximum file size: 5MB
                    </Typography>
                </Stack>

            </Grid>
            <Grid item md={6}>
                <Button onClick={() => {uploadBanner()}} fullWidth variant={'contained'} size={'large'}>+ เพิ่ม Banner</Button>
            </Grid>

        </Grid>
    )
}
export default Banner