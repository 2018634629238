import React from 'react'
import SliderImg from '../latout/imgSlider/SliderImg'
import { Container, Divider, Grid, IconButton, Typography, styled } from '@mui/material';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import slide1 from '../assets/slide1sub.png'
import greensub from '../assets/greensub.png'
import kasub from '../assets/ka.png'
import gangpanang from '../assets/gangpanang.png'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Tomyumkung from '../assets/tomyumgung.png'
import FoodRormmit from '../assets/foodrommit.png'
import kangkewwon from '../assets/kangkewwon.png'
import tomyumoumkon from '../assets/tomyumoumkon.png'
import LOGO from '../assets/LOGOAbout.jpg'
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CallIcon from '@mui/icons-material/Call';
import Cookies from 'universal-cookie';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useRef, useEffect, useState } from 'react';


type Props = {}
const Text36PX = styled(Typography)(({ theme }) => ({
  color: "#436336",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '24px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '26px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '30px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '34px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '36px'
  },
}));
const Text22PX = styled(Typography)(({ theme }) => ({
  color: "#1E4722",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '18px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '22px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '22px'
  },
}));
const Text16PX = styled(Typography)(({ theme }) => ({
  color: "#787F2F",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '12px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '13px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '16px'
  },
}));
const CardsProdList = styled(Grid)(({ theme }) => ({
  borderRadius: "24px",
  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
  padding: "12px 0",
  [theme.breakpoints.up("xs")]: {
    width: "200px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "250px",

  },
  [theme.breakpoints.up("md")]: {
    width: "290px",

  },
  [theme.breakpoints.up("lg")]: {
    width: "350px",
  },
  zIndex: "1",
  backgroundColor: "#FFF",
  margin: "0 auto"
}));
const ProductDetailListcontainer = styled(Grid)(({ theme }) => ({
  width: '90%',
  maxWidth: "1440px",
  margin: "50px auto",
  position: "relative",
}));
const BG = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: "64px",
  clipPath: "polygon(0 0, 100% 35%, 100% 100%, 0% 100%)",
  backgroundColor: "#E3F485",
  position: "absolute",
  top: "0",
  zIndex: "-1",
}));
const IMG = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: "130px",

  },
  [theme.breakpoints.up("sm")]: {
    height: "150px",

  },
  [theme.breakpoints.up("md")]: {
    height: "200px",

  },
  [theme.breakpoints.up("lg")]: {
    height: "250px",
  }
}));

const Logo = styled("img")(({ theme }) => ({
  width: "280px",
}));
const Mapcontainer = styled(Grid)(({ theme }) => ({
  width: '90%',
  maxWidth: "1440px",
  margin: "50px auto",
  height: "500px",
  justifyContent: "center",
  [theme.breakpoints.up("xs")]: {
    height: "200px",
  },
  [theme.breakpoints.up("sm")]: {
    height: "300px",
  },
  [theme.breakpoints.up("md")]: {
    height: "400px",
  },
  [theme.breakpoints.up("xl")]: {
    height: "500px",
  },

}));
function ContactUs({ }: Props) {

  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();

  const [products, setProducts] = useState<any>([]);

  useEffect(() => {

    getProducts();

  }, [])

  const getProducts = () => {
      axios.get(`${process.env.REACT_APP_API_URL}/products/all/1`).then((res) => {
        setProducts(res.data);
      })
  }
  const prodImg = [
    {
      img: slide1
    },
    {
      img: greensub
    },
    {
      img: kasub
    },
    {
      img: gangpanang
    },
  ]
  return (
    <>
      <SliderImg />
      <Text36PX textAlign={"center"}>
        CONTACT US
      </Text36PX>
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Logo src={LOGO} />
        <Grid>
          <Text22PX>
            บริษัท อัฟรอซ จำกัด <br /> 1769 ซอยพัฒนาการ37 ถนนพัฒนาการ <br /> แขวงพัฒนาการเขตสวนหลวง กรุงเทพ 10250
          </Text22PX>
          <Grid container margin={"12px 0"} sx={{ color: "#436336" }} gap={"24px"}>
            <FacebookIcon />
            <EmailIcon />
            <InstagramIcon />
            <YouTubeIcon />
            <CallIcon />
          </Grid>
        </Grid>
      </Grid>
      <Mapcontainer container>
        <iframe style={
                          {
                              borderRadius: '20px',
                              border: 'solid 0px rgb(0,0,0,0)',
                              boxShadow: '0 1rem 2rem rgba(0, 0, 0, .175)'
                          }
                      }
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15494.706465268226!2d100.62448471784592!3d13.858439926050213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d62c8483d2e9f%3A0xef86cec4b9ef36c6!2z4LiV4Lil4Liy4LiU4LiW4LiZ4Lit4Lih4Lih4Li04LiV4Lij!5e0!3m2!1sth!2sth!4v1708631872996!5m2!1sth!2sth" width="80%" height="100%" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </Mapcontainer>
      <Text36PX textAlign={"center"} margin={"12px 0"}>
        สินค้าของเรา
      </Text36PX>
      <ProductDetailListcontainer>
        <BG></BG>
        <Swiper
          style={{ height: "100%", padding: "24px" }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            600: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            }
          }}
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          loop={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper"
        >
          {
            products.map((prod:any) => (
              <SwiperSlide>
                <CardsProdList container>
                  <IMG src={prod.path.replace('{api_path}', process.env.REACT_APP_API_URL)} sx={{ margin: "0 auto" }} />
                  <Grid container flexDirection={"column"}>
                    <Grid container justifyContent={"space-around"}>
                      <Text22PX>
                      {prod.name}
                      </Text22PX>
                      <Text22PX>
                      {prod.price}
                      </Text22PX>
                    </Grid>
                    <Grid container justifyContent={"space-around"}>
                      <Text16PX>
                      {prod.name_eng} <br />
                        <Text16PX sx={{ color: "#B0B0B0" }}>
                        {prod.product_price_name} / Inner box
                        </Text16PX>
                      </Text16PX>
                      <IconButton>
                        <ShoppingCartIcon onClick={()=>navigate("/productdetail/"+prod.id)} sx={{ fontSize: "32px", color: "#787F2F" }} />

                      </IconButton>
                    </Grid>
                  </Grid>
                </CardsProdList>
              </SwiperSlide>
            ))
          }
        </Swiper>

      </ProductDetailListcontainer>
    </>
  )
}

export default ContactUs