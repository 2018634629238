
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AccountCircle, CloudUploadOutlined, DeleteOutline, EditOutlined, SearchOutlined } from "@mui/icons-material"
import { Avatar, Box, Button, FormControl, FormLabel, IconButton, Input, InputAdornment, InputBase, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Paper, Stack, Tab, Tabs, TextField, Typography } from "@mui/material"
import { Grid } from "@mui/material"
import { alpha, styled } from '@mui/material/styles';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie';
import React, { useEffect, useRef, useState } from "react";

const ConfigWebsite = () => {

    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    const [websiteInfoUseState, setWebsiteInfoUseState] = useState<any>({});

    const websiteNameRef = useRef<any>(null);
    const titleRef = useRef<any>(null);
    const descriptionRef = useRef<any>(null);
    const emailRef = useRef<any>(null);
    const phoneRef = useRef<any>(null);
    const facebookRef = useRef<any>(null);
    const youtubeRef = useRef<any>(null);
    const aboutRef = useRef<any>(null);
    const addressRef = useRef<any>(null);
    const mapRef = useRef<any>(null);

    const handleUpload = (event: any) => {
        const file = event.target.files[0];
        // Handle the uploaded file here, for example, you can use FileReader to read the file content
    };

    const getWebsiteInfo = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/information/`).then((response) => {
            const data = response.data.information;

            setWebsiteInfoUseState(data);

        })
    }

    const configWebsiteInfo = () => {
        const websiteName = websiteNameRef.current.value;
        const title = titleRef.current.value;
        const description = descriptionRef.current.value;
        const email = emailRef.current.value;
        const phone = phoneRef.current.value;
        const facebook = facebookRef.current.value;
        const youtube = youtubeRef.current.value;
        const about = aboutRef.current.value;
        const address = addressRef.current.value;
        const map = mapRef.current.value;

        if(title != null && title !== '' && title !== undefined) {
            axios.post(`${process.env.REACT_APP_API_URL}/information/update`, {
                name: websiteName,
                title: title,
                description: description,
                email: email,
                phone_number: phone,
                facebook: facebook,
                youtube: youtube,
                line: '',
                about: about,
                address: address,
                map_url: map
            }, {
                headers: {
                    token: cookies.get('token')
                }
            }).then((response) => {
                if (response.data.message === 'OK') {
                    Swal.fire({
                        title: "อัพเดทข้อมูลเว็บไซต์เรียบร้อยแล้ว",
                        icon: "success",
                    })
                }
                else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: `อัพเดทข้อมูลเว็บไซต์ไม่สำเร็จ (${response.data.message})`,
                        icon: "error",
                    })
                }
            })
        } else {
            const notify = (msg:any, options:any) => toast(msg, {...options});
            notify('Please input website title', {
                duration: 5000,
                style: {
                    color: "white",
                    backgroundColor: "red"
                },
                icon: '❌',
                position: 'bottom-center',
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
            });
        }
    }

    useEffect(() => {
        if (cookies.get('token') == null || cookies.get('token') === '' || cookies.get('token') === undefined) {
            return navigate('/');
        }

        axios.get(`${process.env.REACT_APP_API_URL}/token/check`, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {

            if(response.data.isAdmin !== 1) {
                return navigate('/');
            }
        })

        getWebsiteInfo();
    }, []);

    return (
        <>
            {
                websiteInfoUseState.name !== undefined && <Grid container spacing={2} >
                <Grid item sm={6} md={6}>
                <Toaster />
                    <FormControl fullWidth>
                        <FormLabel>ชื่อเว็บไซต์</FormLabel>
                        <TextField

                            id="standard-bare"
                            variant="outlined"
                            placeholder="ชื่อเว็บไซต์"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.name}
                            inputRef={websiteNameRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>หัวเว็บของเว็บไซต์ (Title)</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="หัวเว็บของเว็บไซต์ (Title)"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.title}
                            inputRef={titleRef}
                        />
                    </FormControl>

                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>คำอธิบายของเว็บไซต์</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="คำอธิบายของเว็บไซต์"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.description}
                            inputRef={descriptionRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>อีเมล</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="อีเมล"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.email}
                            inputRef={emailRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>เบอร์โทรศัพท์</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="เบอร์โทรศัพท์"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.phone_number}
                            inputRef={phoneRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>Facebook Link</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="Facebook Link"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.facebook}
                            inputRef={facebookRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>Youtube Link</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="Youtube Link"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.youtube}
                            inputRef={youtubeRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={12} md={12}>
                    <FormControl fullWidth>
                        <FormLabel>เกี่ยวกับเรา</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="Youtube Link"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.about}
                            inputRef={aboutRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>ที่อยู่</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="ที่อยู่"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.address}
                            inputRef={addressRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>Map Link</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="Map Link"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.map_url}
                            inputRef={mapRef}
                        />
                    </FormControl>
                </Grid>



                <Grid item sm={12} xs={12} md={12}>
                    <Button fullWidth variant={'contained'} onClick={() => {configWebsiteInfo()}}><EditOutlined fontSize={'small'} ></EditOutlined> อัพเดต</Button>
                </Grid>

            </Grid>

            }

        </>
    )
}

export default ConfigWebsite