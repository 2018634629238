import { Button, Grid, IconButton, Typography, styled } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import greensub from '../assets/greensub.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import rice from '../assets/rice1.png'
import rice2 from '../assets/rice2.png'
import rice3 from '../assets/rice3.png'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
type Props = {}
const BuySucceedcontainer = styled(Grid)(({ theme }) => ({
    width: '90%',
    maxWidth: "1440px",
    margin: "50px auto",
    position: "relative",
}));
const Frame = styled(Grid)(({ theme }) => ({
    position: "relative",
    border: "1.1px solid #787F2F",
    borderRadius: "24px",
    // width: "60%",
    alignItems: "center",
    margin: "0 auto",
    justifyContent: "center",
    flexDirection: "column",
    padding: "32px 0",
    rowGap: "24px",
    [theme.breakpoints.up("xs")]: {
        width: "70%",
    },
    [theme.breakpoints.up("sm")]: {
        width: "60%",
    },
}));
const Text48PX = styled(Typography)(({ theme }) => ({
    color: "#436336",
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: '32px'
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: '36px'
    },
    [theme.breakpoints.up("md")]: {
        fontSize: '40px'
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: '44px'
    },
    [theme.breakpoints.up("xl")]: {
        fontSize: '48px'
    },
}));
const Text36PX = styled(Typography)(({ theme }) => ({
    color: "#436336",
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: '16px'
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: '24px'
    },
    [theme.breakpoints.up("md")]: {
        fontSize: '30px'
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: '34px'
    },
    [theme.breakpoints.up("xl")]: {
        fontSize: '36px'
    },
}));
const BuyProductButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#787F2F',
    WebkitTapHighlightColor: '#787F2F',
    '&:hover': {
        backgroundColor: '#FFF',
        color: "#000"
    },
    [theme.breakpoints.up("xs")]: {
        width: "60%",
        fontSize: "13px",

    },
    [theme.breakpoints.up("sm")]: {
        width: "40%",
        fontSize: "15px",

    },
    border: "1px solid #787F2F",
    color: "#fff"
}));
const ImgProd1 = styled("img")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        width: "70px",
    },
    [theme.breakpoints.up("sm")]: {
        width: "100px",
    },
    position: "absolute",
    rotate: "340deg",
    left: "-50px",
    top: "24px"

}));
const ImgProd2 = styled("img")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        width: "70px",
    },
    [theme.breakpoints.up("sm")]: {
        width: "100px",
    },
    position: "absolute",
    rotate: "35deg",
    right: "-50px",
    bottom: "0"

}));
const CheckCircleIconCustom = styled(CheckCircleIcon)(({ theme }) => ({
    color: "#21D268",
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: '90px'
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: '100px'
    },
    [theme.breakpoints.up("md")]: {
        fontSize: '110px'
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: '120px'
    },
    [theme.breakpoints.up("xl")]: {
        fontSize: '130px'
    },
}));
const ProductRicecontainer = styled(Grid)(({ theme }) => ({
    Width: '95%',
    maxWidth: "1440px",
    margin: "24px auto",

    [theme.breakpoints.up("xs")]: {
        height: "340px",
    },
    [theme.breakpoints.up("sm")]: {
        height: "440px",
    },
    [theme.breakpoints.up("md")]: {
        height: "auto",
    },

    justifyContent: "space-around"

}));
const CardsProdRice = styled(Grid)(({ theme }) => ({
    borderRadius: "24px",
    boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
    padding: "12px 0",
    [theme.breakpoints.up("xs")]: {
        width: "150px",
    },
    [theme.breakpoints.up("sm")]: {
        width: "250px",

    },
    [theme.breakpoints.up("md")]: {
        width: "300px",

    },
    [theme.breakpoints.up("lg")]: {
        width: "330px",
    },
    [theme.breakpoints.up("xl")]: {
        width: "380px",
    }
}));
const IMGRice = styled("img")(({ theme }) => ({
    margin: "0 auto",
    [theme.breakpoints.up("xs")]: {
        height: "150px",
    },
    [theme.breakpoints.up("sm")]: {
        height: "300px",
    },
    [theme.breakpoints.up("md")]: {
        height: "350px",
    },
    [theme.breakpoints.up("lg")]: {
        height: "400px",
    },
    [theme.breakpoints.up("xl")]: {
        height: "450px",
    },
}));
const Text22PX = styled(Typography)(({ theme }) => ({
    color: "#1E4722",
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: '14px'
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: '16px'
    },
    [theme.breakpoints.up("md")]: {
        fontSize: '18px'
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: '22px'
    },
    [theme.breakpoints.up("xl")]: {
        fontSize: '22px'
    },
}));
const Text16PX = styled(Typography)(({ theme }) => ({
    color: "#787F2F",
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
      fontSize: '12px'
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: '13px'
    },
    [theme.breakpoints.up("md")]: {
      fontSize: '14px'
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: '16px'
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: '16px'
    },
  }));
function BuySucceed({ }: Props) {
    const Navigate = useNavigate()
    const prodImgRice = [
        {
            img: rice
        },
        {
            img: rice2
        },
        {
            img: rice3
        },
    ]
    return (
        <>
            <BuySucceedcontainer container>
                <Frame container>
                    <CheckCircleIconCustom />
                    <Text48PX>
                        สั่งซื้อสำเร็จ
                    </Text48PX>
                    <Text36PX>
                        ขอบคุณสำหรับการสั่งซื้อ
                    </Text36PX>
                    <BuyProductButton onClick={() => Navigate("/")}>
                        กลับไปหน้า SHOP
                    </BuyProductButton>
                    <ImgProd1 src={greensub} />
                    <ImgProd2 src={greensub} />

                </Frame>
            </BuySucceedcontainer>
            <Text36PX textAlign={"center"} margin={"12px 0"}>
                สินค้าของเรา
            </Text36PX>
            {/* ========= rice product desktop ========== */}

            <ProductRicecontainer container sx={{ display: { xs: "none", md: "flex" } }}>
                {
                    prodImgRice.map(prod => (
                        <CardsProdRice container>
                            <IMGRice src={prod.img} />
                            <Grid container flexDirection={"column"}>
                                <Grid container justifyContent={"space-around"}>
                                    <Text22PX>
                                        Lorem ipsum
                                    </Text22PX>
                                    <Text22PX>
                                        80 ฿
                                    </Text22PX>
                                </Grid>
                                <Grid container justifyContent={"space-around"}>
                                    <Text16PX sx={{ color: "#B0B0B0" }}>
                                        <br />
                                        227 g. / Nylon Pouch
                                    </Text16PX>
                                    <IconButton>
                                        <ShoppingCartIcon sx={{ fontSize: "32px", color: "#787F2F" }} />

                                    </IconButton>
                                </Grid>
                            </Grid>
                        </CardsProdRice>
                    ))
                }
            </ProductRicecontainer>

            {/* ========= rice product mobile ========== */}
            <ProductRicecontainer sx={{ display: { xs: "block", md: "none" } }}>
                <Swiper
                    style={{ height: "100%" }}
                    className="sample-slider"
                    modules={[Autoplay]}
                    loop={true}
                    autoplay={{
                        delay: 3,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    speed={3000}
                    grabCursor={false}
                    breakpoints={{
                        0: {
                            slidesPerView: 2,
                        },
                        600: {
                            slidesPerView: 2,
                        },
                    }}
                >
                    {
                        prodImgRice.map(prod => (
                            <SwiperSlide>
                                <CardsProdRice container>
                                    <IMGRice src={prod.img} />
                                    <Grid container flexDirection={"column"}>
                                        <Grid container justifyContent={"space-around"}>
                                            <Text22PX>
                                                Lorem ipsum
                                            </Text22PX>
                                            <Text22PX>
                                                80 ฿
                                            </Text22PX>
                                        </Grid>
                                        <Grid container justifyContent={"space-around"}>
                                            <Text16PX sx={{ color: "#B0B0B0" }}>
                                                <br />
                                                227 g. / Nylon Pouch
                                            </Text16PX>
                                            <IconButton>
                                                <ShoppingCartIcon sx={{ fontSize: "32px", color: "#787F2F" }} />

                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </CardsProdRice>
                            </SwiperSlide>

                        ))
                    }

                </Swiper>
            </ProductRicecontainer>
        </>
    )
}

export default BuySucceed