import React from 'react'
import { Button, Grid, IconButton, Typography, styled } from '@mui/material';
import slide1 from '../assets/slide1sub.png'
import greensub from '../assets/greensub.png'
import kasub from '../assets/ka.png'
import gangpanang from '../assets/gangpanang.png'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { Swiper, SwiperSlide } from 'swiper/react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Autoplay, Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import Cookies from 'universal-cookie';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useRef, useEffect, useState } from 'react';
import Swal from 'sweetalert2'

const Text36PX = styled(Typography)(({ theme }) => ({
    color: "#436336",
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: '24px'
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: '26px'
    },
    [theme.breakpoints.up("md")]: {
        fontSize: '30px'
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: '34px'
    },
    [theme.breakpoints.up("xl")]: {
        fontSize: '36px'
    },
}));
const Text22PX = styled(Typography)(({ theme }) => ({
    color: "#1E4722",
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: '14px'
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: '16px'
    },
    [theme.breakpoints.up("md")]: {
        fontSize: '18px'
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: '22px'
    },
    [theme.breakpoints.up("xl")]: {
        fontSize: '22px'
    },
}));
const Text16PX = styled(Typography)(({ theme }) => ({
    color: "#787F2F",
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: '12px'
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: '13px'
    },
    [theme.breakpoints.up("md")]: {
        fontSize: '14px'
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: '16px'
    },
    [theme.breakpoints.up("xl")]: {
        fontSize: '16px'
    },
}));
const Text28PX = styled(Typography)(({ theme }) => ({
    color: "#323334",
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: '16px'
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: '20px'
    },
    [theme.breakpoints.up("md")]: {
        fontSize: '24px'
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: '26px'
    },
    [theme.breakpoints.up("xl")]: {
        fontSize: '28px'
    },
}));
const ProductDetailContainer = styled(Grid)(({ theme }) => ({
    width: '90%',
    maxWidth: "1440px",
    margin: "50px auto",
    position: "relative",
    justifyContent: "space-between"
}));
const ImgProduct = styled("img")(({ theme }) => ({
    width: "80%",
    margin: "0 auto",
    // height:"auto"
}));
const CardIMG = styled(Grid)(({ theme }) => ({
    border: "1px solid #D4D4D4",
    borderRadius: "24px",
    [theme.breakpoints.up("xs")]: {
        width: "55%",
    },
    [theme.breakpoints.up("sm")]: {
        width: "40%",
    },

}));
const DetailTextContainer = styled(Grid)(({ theme }) => ({
    gap: "12px",
    flexDirection: "column",
    [theme.breakpoints.up("xs")]: {
        width: "40%",
    },
    [theme.breakpoints.up("sm")]: {
        width: '55%',
    },
}));
const AddtoCartButton = styled(Button)(({ theme }) => ({
    // backgroundColor: '#939393',
    WebkitTapHighlightColor: '#787F2F',
    '&:hover': {
        backgroundColor: '#787F2F',
        color: "#FFF"

    },
    fontSize: "15px",
    [theme.breakpoints.up("xs")]: {
        width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
        width: "39%",
    },
    border: "1px solid #D0D5DD",
    color: "#000"
}));
const BuyProductButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#787F2F',
    WebkitTapHighlightColor: '#787F2F',
    '&:hover': {
        backgroundColor: '#FFF',
        color: "#000"
    },
    fontSize: "15px",
    [theme.breakpoints.up("xs")]: {
        width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
        width: "55%",
    },
    border: "1px solid #787F2F",
    color: "#fff"
}));
const ProductDetailListcontainer = styled(Grid)(({ theme }) => ({
    width: '90%',
    maxWidth: "1440px",
    margin: "50px auto",
    position: "relative",
}));
const BG = styled(Grid)(({ theme }) => ({
    width: "100%",
    height: "100%",
    borderRadius: "64px",
    clipPath: "polygon(0 0, 100% 35%, 100% 100%, 0% 100%)",
    backgroundColor: "#E3F485",
    position: "absolute",
    top: "0",
    zIndex: "-1",
}));
const IMG = styled("img")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        height: "130px",

    },
    [theme.breakpoints.up("sm")]: {
        height: "150px",

    },
    [theme.breakpoints.up("md")]: {
        height: "200px",

    },
    [theme.breakpoints.up("lg")]: {
        height: "250px",
    }
}));
const CardsProdList = styled(Grid)(({ theme }) => ({
    borderRadius: "24px",
    boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
    padding: "12px 0",
    [theme.breakpoints.up("xs")]: {
        width: "200px",
    },
    [theme.breakpoints.up("sm")]: {
        width: "250px",

    },
    [theme.breakpoints.up("md")]: {
        width: "280px",

    },
    [theme.breakpoints.up("lg")]: {
        width: "350px",
    },
    zIndex: "1",
    backgroundColor: "#FFF",
    margin: "0 auto"
}));
type Props = {}

function ProductDetail({ }: Props) {

    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    const params = useParams();

    const [carts, setCarts] = useState([{
        product_name: 'test',
        product_price_name: '120'
    }]);

    const [total, setTotal] = useState<any>(1);
    const [product, setProduct] = useState<any>([{}]);
    const [products, setProducts] = useState<any>([]);

    useEffect(() => {

        if (cookies.get('token') != null && cookies.get('token') !== '' && cookies.get('token') !== undefined) {

        } else {
            return navigate('/login');
        }

        getProducts();
    
      }, [])

      useEffect(() => {
        getProduct(params.id);
      }, [params.id])

      const getProduct = (product_id:any) => {

        axios.get(`${process.env.REACT_APP_API_URL}/products/id/${product_id}`).then((res) => {
            setProduct(res.data.product);
          })
      }

      const getProducts = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/products/all/1`).then((res) => {
            setProducts(res.data);
            })
        }

      const addQuantity = (total:any, stock:any) => {

        let totalQty = total + 1;

        if(totalQty <= stock) {
            setTotal(total += 1);
        } else {
            Swal.fire({
                title: "จำนวณสินค้าไม่เพียงพอ",
                text: "มีสินค้าจำนวณ "+stock+" ชิ้น",
                icon: "error",
            })
        }
       
      }
    
      const removeQuantity = (total:any) => {
        let totalCal = (total <= 1)? 1 : total -= 1;
        setTotal(totalCal);
      }

      const addCart = (produt_id:any, price_id:any, isRedirect:boolean) => {

        const body = {
            product_id : produt_id,
            product_price_id : price_id,
            quantity : total
        };

        axios.post(`${process.env.REACT_APP_API_URL}/products/addtocart`, body,{
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if (response.data.message === 'success') {

                if(isRedirect) {
                    navigate("/cart")
                } else {
                    Swal.fire({
                        title: "เพิ่มสินค้าเรียบร้อยแล้ว",
                        icon: "success",
                    })
                }
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: `เพิ่มสินค้าไม่สำเร็จ (${response.data.message})`,
                    icon: "error",
                })
            }
        })

      }

    const prodImg = [
        {
            img: slide1
        },
        {
            img: greensub
        },
        {
            img: kasub
        },
        {
            img: gangpanang
        },
    ]
    return (
        <>
            <ProductDetailContainer container>
                <CardIMG container>
                    <ImgProduct src={product[0].path !== undefined && product[0].path != null && product[0].path.replace('{api_path}', process.env.REACT_APP_API_URL)} />
                </CardIMG>
                <DetailTextContainer container>
                    <Text28PX>
                        {product[0].name}
                    </Text28PX>
                    <Text16PX>
                        {product[0].name_eng}
                    </Text16PX>
                    <Text16PX>
                        {product[0].product_price_name} / Inner box
                    </Text16PX>
                    <Text36PX>
                        {product[0].price}
                    </Text36PX>
                    <Text16PX>
                        เลือกจำนวน
                    </Text16PX>
                    <Grid container alignItems={"center"} gap={"12px"}>
                        <IconButton onClick={() => addQuantity(total, product[0].stock)}>
                            <AddCircleIcon />
                        </IconButton>
                        <Text22PX>
                            {total}
                        </Text22PX>
                        <IconButton onClick={() => removeQuantity(total)}>
                            <DoNotDisturbOnIcon />
                        </IconButton>
                    </Grid>
                    <Grid container justifyContent={"space-between"} rowGap={"16px"} flexDirection={{ xs: "column", sm: "row" }}>
                        <AddtoCartButton onClick={() => addCart(product[0].id, product[0].price_id, false)}>
                            เพิ่มลงตระกร้า
                        </AddtoCartButton>
                        <BuyProductButton onClick={() => addCart(product[0].id, product[0].price_id, true)}>
                            สั่งซื้อ
                        </BuyProductButton>
                    </Grid>
                </DetailTextContainer>
                <Text36PX textAlign={"center"} margin={"12px 0"}>
                    สินค้าของเรา
                </Text36PX>

            </ProductDetailContainer>
            <ProductDetailListcontainer>
                <BG></BG>
                <Swiper
                    style={{ height: "100%", padding: "24px" }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        600: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        }
                    }}
                    cssMode={true}
                    navigation={true}
                    pagination={true}
                    mousewheel={true}
                    keyboard={true}
                    loop={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    className="mySwiper"
                >
                    {
                        products.map((prod:any) => (
                            <SwiperSlide>
                                <CardsProdList container>
                                    <IMG src={prod.path.replace('{api_path}', process.env.REACT_APP_API_URL)} sx={{ margin: "0 auto" }} />
                                    <Grid container flexDirection={"column"}>
                                        <Grid container justifyContent={"space-around"}>
                                            <Text22PX>
                                            {prod.name}
                                            </Text22PX>
                                            <Text22PX>
                                            {prod.price}
                                            </Text22PX>
                                        </Grid>
                                        <Grid container justifyContent={"space-around"}>
                                            <Text16PX>
                                            {prod.name_eng} <br />
                                                <Text16PX sx={{ color: "#B0B0B0" }}>
                                                {prod.product_price_name} / Inner box
                                                </Text16PX>
                                            </Text16PX>
                                            <IconButton>
                                                <ShoppingCartIcon onClick={()=>navigate("/productdetail/"+prod.id)} sx={{ fontSize: "32px", color: "#787F2F" }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </CardsProdList>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>

            </ProductDetailListcontainer>
        </>
    )
}

export default ProductDetail