import * as React from 'react';
import { Paper, Typography, TextField, Button, Grid, Box } from '@mui/material';
import circle from '../assets/login/Ellipse 24.png';
import { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';

function Reset() {

  const emailOrPhoneRef = useRef<any>(null);
  const notify = (msg:any, options:any) => toast(msg, {...options});
  const navigate = useNavigate();

  const Resetpassword = async () => {

    const emailOrPhone = emailOrPhoneRef.current.value;

    if (emailOrPhone != null && emailOrPhone !== '' && emailOrPhone !== undefined) {

      const sendData = {
        url: `${process.env.REACT_APP_BASE_URL}`,
        email: emailOrPhone,
        phone: emailOrPhone
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/members/reset/password`, sendData);

      if (response.data.message === 'Please check your email to change your password') {
          Swal.fire({
            title: "ส่งข้อมูลเรียบร้อย",
            text: 'กรุณาตรวจสอบอีเมล เพื่อตั้งค่ารหัสผ่านใหม่',
            icon: "success"
        }).then(() => {
          return navigate('/');
        })
      } else {
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: response.data.message,
          icon: "error"
      })
      }

    } else {
        notify('กรุณากรอกข้อมูลให้ครบ', {
          duration: 5000,
          style: {
              color: "white",
              backgroundColor: "red"
          },
          icon: '❌',
          position: 'bottom-center',
          iconTheme: {
              primary: '#000',
              secondary: '#fff',
          },
      });
    }

  }

  return (
    <Grid
      container
      sx={{
        p: { xs: 1, md: 10 } ,
        position: 'relative',
        background: '#FFFFFF',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: { xs: 'auto', md: 'hidden' }
      }}
    >
      <Toaster />
      <Grid item md={6} textAlign={'center'} sx={{ p: { xs: 5, md: 5 } }}>
      </Grid>
      <Grid item md={6} textAlign={'left'} sx={{ p: { xs: 1, md: 5 } }}>
        <Paper elevation={2} sx={{ p: { xs: 3, md: 8 }, borderRadius: 4 }}>
          <Typography variant='h4' sx={{ color: '#094a2a', fontWeight: '600' }}>
            ลืมรหัสผ่าน
          </Typography>
          <br></br>
          <Typography variant='body1'>
            กรุณากรอกอีเมล หรือเบอร์โทรศัพท์ เพื่อตั้งค่ารหัสผ่านใหม่ <br />
          </Typography>
          <br></br>
          <Typography variant='body1' sx={{ color: '#949CA9' }}>
            อีเมล หรือ เบอรโทรศัพท์
          </Typography>
          <TextField
            id="outlined-lastName-input"
            type="text"
            autoComplete="current-lastName"
            variant="outlined"
            fullWidth
            sx={{
              borderRadius: '4px',
            }}
            inputRef={emailOrPhoneRef}
          />
          <br></br>
          <br></br>
          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              height: '50px',
              borderRadius: '10px',
              bgcolor: '#AEC782',
            }}
            onClick={() => Resetpassword()}
          >
            ส่งข้อมูล
          </Button>

        </Paper>


      </Grid>
      <Box sx={{ position: 'absolute', left: '-10%', top: '-10%' }}>
        <img src={circle} alt="" />
      </Box>
      <Box sx={{ position: 'absolute', right: '-10%', bottom: '-10%' }}>
        <img src={circle} alt="" />
      </Box>
    </Grid>

  );
}

export default Reset;
