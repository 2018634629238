import { Button, Checkbox, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Typography, styled } from '@mui/material';
import React from 'react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import greensub from '../assets/greensub.png'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import AddCardIcon from '@mui/icons-material/AddCard';
import paypal from '../assets/paypal.png'
import Cookies from 'universal-cookie';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useRef, useEffect, useState } from 'react';
import Swal from 'sweetalert2'

type Props = {}
const Text36PX = styled(Typography)(({ theme }) => ({
  color: "#436336",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '24px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '26px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '30px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '34px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '36px'
  },
}));
const Text22PX = styled(Typography)(({ theme }) => ({
  color: "#1E4722",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '18px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '22px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '22px'
  },
}));
const Text16PX = styled(Typography)(({ theme }) => ({
  color: "#787F2F",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '12px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '13px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '16px'
  },
}));
const Text28PX = styled(Typography)(({ theme }) => ({
  color: "#323334",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '20px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '24px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '26px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '28px'
  },
}));
const CartContainer = styled(Grid)(({ theme }) => ({
  width: '90%',
  maxWidth: "1440px",
  margin: "50px auto",
  position: "relative",
}));
const ListCartContainer = styled(Grid)(({ theme }) => ({
  margin: "50px auto",
  position: "relative",
  alignItems: "center",
  flexWrap: "nowrap",
  [theme.breakpoints.up("xs")]: {
    columnGap: "12px",

  },
  [theme.breakpoints.up("sm")]: {
    columnGap: "24px",

  },

}));
const ImgProduct = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "100px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "180px",
  },
}));
const DetailTextContainer = styled(Grid)(({ theme }) => ({
  gap: "12px",
  flexDirection: "column",
  [theme.breakpoints.up("xs")]: {
    width: "40%",
  },
  [theme.breakpoints.up("sm")]: {
    width: '55%',
  },
}));
const TotalAmountContainer = styled(Grid)(({ theme }) => ({
  flexDirection: "column",
  gap: "24px",
  margin: "24px 0"
}));
const HowtoPayment = styled(Grid)(({ theme }) => ({
  flexDirection: "column",
  gap: "24px",
  margin: "24px 0"
}));
const BuyProductButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#787F2F',
  WebkitTapHighlightColor: '#787F2F',
  '&:hover': {
    backgroundColor: '#FFF',
    color: "#000"
  },
  fontSize: "15px",
  [theme.breakpoints.up("xs")]: {
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "30%",
  },
  border: "1px solid #787F2F",
  color: "#fff"
}));
const ImgPayPal = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "100px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "130px",
  },
}));
function Cart({ }: Props) {
  const cart = [1, 2]
  const Navigate = useNavigate()

  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();

  const [carts, setCarts] = useState([{
      product_name: 'test',
      product_price_name: '120'
  }]);

  const [address, setAddress] = useState<any>('');

  const [total, setTotal] = useState<any>(0);

  useEffect(() => {

    if (cookies.get('token') != null && cookies.get('token') !== '' && cookies.get('token') !== undefined) {
      const user_data = cookies.get('data');
      setAddress('คุณ ' + user_data.firstname + ' ' + user_data.lastname + ' ' + 'เบอร์โทรศัพท์ ' + user_data.phone + ' ที่อยู่ ' + user_data.address);
      reloadCarts();
    } else {
        return navigate('/login');
    }

  }, [])

  const reloadCarts = () => {
      axios.get(`${process.env.REACT_APP_API_URL}/carts/get`, {
          headers: {
              token: cookies.get('token')
          }
      }).then((response) => {
          const sortCartsByProductCreated = response.data.carts.sort(function (a: any, b: any) {
              var d1: any = new Date(a.productCreatedAt);
              var d2: any = new Date(b.productCreatedAt);
              return d1 - d2;
          });
          const sortCartsByPriceCreated = sortCartsByProductCreated.sort(function (a: any, b: any) {
              var d1: any = new Date(a.priceCreatedAt);
              var d2: any = new Date(b.priceCreatedAt);
              return d1 - d2;
          })

          updateTotal(sortCartsByPriceCreated);
          setCarts(sortCartsByPriceCreated);
          // setTotals(response.data.totals);
      })
  }

  const addQuantity = async (product_id:any) => {

    let tempCarts:any = [];
    tempCarts = [...carts];

    const findIndex = tempCarts.findIndex((element:any) => element.product_id === product_id);

    if(findIndex === -1) {
      return;
    }

    let totalQty = 0;

    await axios.get(`${process.env.REACT_APP_API_URL}/products/id/${tempCarts[findIndex].product_id}`).then((res) => {
      totalQty = res.data.product[0].stock;
    })

    if((tempCarts[findIndex].quantity + 1) <= totalQty) {
      tempCarts[findIndex].quantity += 1;

      updateTotal(tempCarts);
      setCarts(tempCarts);
    } else {
      Swal.fire({
          title: "จำนวณสินค้าไม่เพียงพอ",
          text: "มีสินค้าจำนวณ "+totalQty+" ชิ้น",
          icon: "error",
      })
    }
    

  }

  const removeQuantity = (product_id:any) => {
    let tempCarts:any = [];
    tempCarts = [...carts];

    const findIndex = tempCarts.findIndex((element:any) => element.product_id === product_id);

    if(findIndex === -1) {
      return;
    }

    tempCarts[findIndex].quantity = ((tempCarts[findIndex].quantity - 1) <= 1)? 1 : tempCarts[findIndex].quantity - 1;

    updateTotal(tempCarts);
    setCarts(tempCarts);
  }

  const updateTotal = async(data:any) => {

    let tempTotal = 0;
    await Promise.all(data.map((cart:any) => {
      tempTotal += (cart.price_per_each * cart.quantity);
    }));

    setTotal(tempTotal);
  }

  const Buy = async () => {

    const subData:any = [];

    await carts.map((dataDetails:any) => {
        subData.push({
                    product_id : dataDetails.product_id,
                    product_price_id : dataDetails.product_price_id,
                    quantity : dataDetails.quantity
                });
    })

    axios.post(`${process.env.REACT_APP_API_URL}/orders/add/`, {
        data: JSON.stringify(subData),
        address: address
    }, {
        headers: {
            token: cookies.get('token')
        }
    }).then((response) => {
        if(response.data.message === 'OK')
        {
            localStorage.removeItem('data');

            Swal.fire({
                title: "สั่งซื้อสินค้าเรียบร้อยแล้ว",
                icon: "success",
            }).then(() => {
                navigate('/succeed');
            })
        } else {
            Swal.fire({
                title: "เกิดข้อผิดพลาด",
                text: `สั่งซื้อสินค้าไม่สำเร็จ (${response.data.message})`,
                icon: "error",
            })
        }
    })

  }

  return (
    <CartContainer>
      <Text36PX textAlign={"center"}>
        <ShoppingCartIcon />
        สั่งซื้อ
      </Text36PX>
      {
        carts.length > 0 && carts.map((cart:any) => (
          <>
            <ListCartContainer container>
              <FormControlLabel sx={{ margin: "0" }} control={<Checkbox defaultChecked />} label="" />
              <ImgProduct src={cart.product_image !== undefined && cart.product_image != null && cart.product_image.replace('{api_path}', process.env.REACT_APP_API_URL)} />
              <DetailTextContainer container>
                <Text28PX>
                  {cart.product_name}
                </Text28PX>
                <Text16PX>
                {cart.product_name_eng}
                </Text16PX>
                <Text16PX>
                {cart.product_price_name} / Inner box
                </Text16PX>
                <Text36PX sx={{ textWrap: "nowrap", display: { xs: "block", sm: "none" } }}>
                {parseFloat((cart.price_per_each * cart.quantity).toString()).toLocaleString("en-US", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                        })}
                </Text36PX>
                <Grid container alignItems={"center"} gap={"12px"}>
                  <IconButton onClick={() => addQuantity(cart.product_id)}>
                    <AddCircleIcon/>
                  </IconButton>
                  <Text22PX>
                  {cart.quantity}
                  </Text22PX>
                  <IconButton onClick={() => removeQuantity(cart.product_id)}>
                    <DoNotDisturbOnIcon />
                  </IconButton>
                </Grid>
              </DetailTextContainer>
              <Text36PX textAlign={"center"} sx={{ textWrap: "nowrap", display: { xs: "none", sm: "block" } }}>
              {parseFloat((cart.price_per_each * cart.quantity).toString()).toLocaleString("en-US", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                        })}
              </Text36PX>
            </ListCartContainer>
            <Divider />
          </>
        ))
      }
      <TotalAmountContainer container>
        <Grid container justifyContent={"space-between"}>
          <Text22PX>
            Total Amount:
          </Text22PX>
          <Text36PX>
            {parseFloat(total).toLocaleString("en-US", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                        })}
          </Text36PX>
        </Grid>
        <Grid container justifyContent={"space-between"}>
          <Text22PX>
            Shipping Fee:
          </Text22PX>
          <Text36PX>
            ฿ 0
          </Text36PX>
        </Grid>
        <Grid container justifyContent={"space-between"}>
          <Text22PX>
            Tax:
          </Text22PX>
          <Text36PX>
            ฿ 0
          </Text36PX>
        </Grid>
        <Divider />
      </TotalAmountContainer>
      <TotalAmountContainer container>
        <Text28PX>
          รายการจัดส่ง
        </Text28PX>
        <Grid container gap={"14px"}>
          <AccessTimeIcon />
          <Text22PX>
            ระยะเวลาจัดส่ง<br />
            1 - 2 Days
          </Text22PX>
        </Grid>
        <Grid container gap={"14px"}>
          <PersonPinCircleIcon />
          <Text22PX>
            สถานที่จัดส่ง<br />
            {address}
          </Text22PX>
        </Grid>
        <Divider />
      </TotalAmountContainer>
      <HowtoPayment container>
        <Text28PX>
          วิธีการชำระเงิน
        </Text28PX>
        <FormControl>
          <FormLabel>หมายเลขบัตรเครดิต/เดบิต</FormLabel>
          <RadioGroup
            row
            defaultValue="cadit"
          >
            <FormControlLabel value="cadit" control={<Radio />} label={<AddCardIcon />} />
            <FormControlLabel value="paypal" control={<Radio />} label={<ImgPayPal src={paypal} />} />
          </RadioGroup>
        </FormControl>

      </HowtoPayment>
      <Grid textAlign={"end"}>
        <Text22PX>
          Total
        </Text22PX>
        <Divider sx={{ borderColor: "#787F2F" }} />
        <Text36PX sx={{ color: "#787F2F" }}>
        {parseFloat(total).toLocaleString("en-US", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                        })}
        </Text36PX>
        <BuyProductButton onClick={() => Buy()}>
          สั่งซื้อ
        </BuyProductButton>
      </Grid>
    </CartContainer>
  )
}

export default Cart