
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AccountCircle, CloudUploadOutlined, DeleteOutline, EditOutlined, SearchOutlined } from "@mui/icons-material"
import { Avatar, Box, Button, Container, FormControl, FormLabel, IconButton, Input, InputAdornment, InputBase, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, MenuItem, Paper, Select, Stack, Tab, Tabs, TextField, Typography } from "@mui/material"
import { Grid } from "@mui/material"
import { alpha, styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { unescape } from 'querystring';

const EditProduct = () => {

    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    const notify = (msg:any, options:any) => toast(msg, {...options});
    const params = useParams();

    const [categoriesRef, setCategoriesRef] = useState<any>([]);
    const productImageRef = useRef<any>('');
    const productIdRef = useRef<any>('');
    const productDetailsNameRef = useRef<any>('');
    const productDetailsNameEngRef = useRef<any>('');
    const productNameRef = useRef<any>('');
    const productPriceRef = useRef<any>('');
    const productStockRef = useRef<any>('');
    const descriptionRef = useRef<any>('');
    const [categoryRef, setCategoryRef] = useState<any>('');
    const [categoryValue, setCategoryValue] = useState<any>(0);
    const [updateId, setUpdateId] = useState<any>('');

    const [product, setProduct] = useState<any>([{}]);

    const [fileImg, setFileImg] = useState<any>(undefined);

    const handleUpload = (event: any) => {

        if(event.target.files[0] === undefined) {
            return;
        }

        setFileImg(event.target.files[0]);

        // Handle the uploaded file here, for example, you can use FileReader to read the file content
    };

    const editorRef = useRef<any>(null);
    const [editorLoaded, setEditorLoaded] = useState(false);

    useEffect(() => {
        if (cookies.get('token') == null || cookies.get('token') === '' || cookies.get('token') === undefined) {
            return navigate('/adminLogin');
        }

        axios.get(`${process.env.REACT_APP_API_URL}/token/check`, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {

            if(response.data.isAdmin !== 1) {
                return navigate('/');
            }
        })

        getProduct(params.id);

        setEditorLoaded(true);
        handleResize(); // Call handleResize on component mount to set initial size
        getCategories();

        window.addEventListener('resize', handleResize); // Add event listener for window resize
        return () => {
            window.removeEventListener('resize', handleResize); // Clean up event listener on component unmount
            setEditorLoaded(false);
        };

        
    }, []);

    const getProduct = (product_id:any) => {

        axios.get(`${process.env.REACT_APP_API_URL}/products/id/${product_id}`).then((res) => {

            productIdRef.current.value = res.data.product[0].code;
            productDetailsNameRef.current.value = res.data.product[0].name;
            productPriceRef.current.value = Math.round(res.data.product[0].price);
            productStockRef.current.value = Math.round(res.data.product[0].stock);
            productNameRef.current.value = res.data.product[0].product_price_name;
            descriptionRef.current.value = res.data.product[0].description;
            productDetailsNameEngRef.current.value = res.data.product[0].name_eng;
            setCategoryValue(res.data.product[0].category_id);
            setUpdateId(res.data.product[0].id);

            setProduct(res.data.product);
          })
      }

    const handleResize = () => {
        if (editorRef.current) {
            // Get the parent container's width
            const parentWidth = editorRef.current.parentElement.clientWidth;
            // Set the editor's width to be 90% of the parent container's width
            editorRef.current.style.width = `${parentWidth * 0.9}px`;
        }
    };

    const getCategories = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/categories/all`).then((response) => {
            const data = response.data;

            setCategoriesRef(data);
        })
    }

    const handleChange = (event:any) => {
        setCategoryValue(event.target.value);
        setCategoryRef(event.target.value);
      };

    const addProduct = async () => {

        const productId = productIdRef.current.value;
        const productDetailsName = productDetailsNameRef.current.value;
        const productName = productNameRef.current.value;
        const productPrice = productPriceRef.current.value;
        const productStock = productStockRef.current.value;
        const productDetailsNameEng = productDetailsNameEngRef.current.value;
        const description = descriptionRef.current.editor.getData().replace(/<p>/g, '').replace(/<\/p>/g, '');

        if(productId != null && productId !== '' && productId !== undefined
        && productName != null && productName !== '' && productName !== undefined
        && productPrice != null && productPrice !== '' && productPrice !== undefined
        && productStock != null && productStock !== '' && productStock !== undefined
        && productDetailsName != null && productDetailsName !== '' && productDetailsName !== undefined
        && productDetailsNameEng != null && productDetailsNameEng !== '' && productDetailsNameEng !== undefined
        && categoryValue != null && categoryValue !== '' && categoryValue !== undefined) {

            await axios.post(`${process.env.REACT_APP_API_URL}/products/update`, {
                id : updateId,
                code : productId,
                name : productDetailsName,
                namePrice : productName,
                description : description,
                category_id : categoryValue,
                price : productPrice,
                stock : productStock,
                name_eng : productDetailsNameEng
            }, {
                headers: {
                    token: cookies.get('token')
                }
            }).then((response) => {

                if (response.data.message === 'OK') {
                    Swal.fire({
                        title: "แก้ไขสินค้าเรียบร้อยแล้ว",
                        icon: "success",
                    }).then( async() => {

                        if(fileImg !== undefined) {
                            const formData = new FormData();
                            const product_id = response.data.id
                            formData.append('file', fileImg);

                            await axios.post(`${process.env.REACT_APP_API_URL}/upload/productImg`, formData, {
                                headers: {
                                    token: cookies.get('token')
                                }
                            }).then( async (response) => {

                                if(response.data.message == 'OK')
                                {
                                    await axios.post(`${process.env.REACT_APP_API_URL}/products/updatepathimg`, {
                                        product_id: product_id,
                                        path_img: response.data.path
                                    }, {
                                        headers: {
                                            token: cookies.get('token')
                                        }
                                    })
                                }
                            })
                        }
                        return navigate('/adminPage');
                    })
                }
                else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: `แก้ไขสินค้าไม่สำเร็จ (${response.data.message})`,
                        icon: "error",
                    })
                }
            })

        } else {
            notify('Please input your product data', {
                duration: 5000,
                style: {
                    color: "white",
                    backgroundColor: "red"
                },
                icon: '❌',
                position: 'bottom-center',
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
            });
        }

    }


    return (
        <Container sx={
            {
                my: 3
            }
        }>
            <Toaster></Toaster>
            <Stack direction="row" alignItems="center">
                <input
                    accept="image/*"
                    id="upload-photo"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleUpload}
                />
                <label htmlFor="upload-photo">
                    <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadOutlined />}
                    >
                        Upload Photo
                    </Button>
                </label>

                <Typography variant="body2" color="textSecondary">
                    &nbsp;&nbsp;Maximum file size: 5MB
                </Typography>
            </Stack>
            <br></br>


            <Grid container spacing={2} >
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>เลือกหมวดหมู่</FormLabel>
                        <Select
                            id="standard-bare"
                            variant="outlined"
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            value={categoryValue}
                            onChange={handleChange}
                        >
                            {categoriesRef.map((item:any) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>รหัสสินค้า</FormLabel>
                        <TextField

                            id="standard-bare"
                            variant="outlined"
                            placeholder="รหัสสินค้า"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            inputRef={productIdRef}
                        />
                    </FormControl>
                </Grid>

                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>ชื่อสินค้า (ภาษาไทย)</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="ชื่อสินค้า (ภาษาไทย)"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            inputRef={productDetailsNameRef}
                        />
                    </FormControl>

                </Grid>

                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>ชื่อสินค้า (ภาษาอังกฤษ)</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="ชื่อสินค้า (ภาษาอังกฤษ)"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            inputRef={productDetailsNameEngRef}
                        />
                    </FormControl>

                </Grid>

                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>ปริมาณ/ปริมาตร ของสินค้า</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="ปริมาณ/ปริมาตร ของสินค้า"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            inputRef={productNameRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>ราคา</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="ราคา"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            type="number"
                            inputRef={productPriceRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>จำนวน Stock</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="ราคา"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            type="number"
                            inputRef={productStockRef}
                        />
                    </FormControl>
                </Grid>

                <Grid item sm={12} md={12}>
                    <div className="App" ref={editorRef}>
                        <FormLabel>คำอธิบาย</FormLabel>
                        {editorLoaded && (
                            <CKEditor
                                editor={ClassicEditor}
                                data={descriptionRef.current.value}
                                ref={descriptionRef}
                                onReady={(editor: any) => {
                                    editor.ui.view.editable.element.style.height = '250px';
                                    // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event) => {
                                }}
                                onBlur={(event, editor) => {
                                }}
                                onFocus={(event, editor) => {
                                }}
                            />
                        )}
                    </div>
                </Grid>

                <Grid item sm={12} md={12}>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <Button fullWidth variant={'contained'} onClick={() => {addProduct()}}><EditOutlined fontSize={'small'}></EditOutlined> แก้ไขสินค้า</Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>




        </Container>
    )
}

export default EditProduct