import React from 'react'
import SliderImg from '../latout/imgSlider/SliderImg'
import { Container, Divider, Grid, IconButton, Typography, styled } from '@mui/material';
import slide1 from '../assets/slide1sub.png'
import greensub from '../assets/greensub.png'
import kasub from '../assets/ka.png'
import gangpanang from '../assets/gangpanang.png'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import rice from '../assets/rice1.png'
import rice2 from '../assets/rice2.png'
import rice3 from '../assets/rice3.png'
import LOGO from '../assets/LOGOAbout.jpg'
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CallIcon from '@mui/icons-material/Call';
import Tomyumkung from '../assets/tomyumgung.png'
import FoodRormmit from '../assets/foodrommit.png'
import kangkewwon from '../assets/kangkewwon.png'
import tomyumoumkon from '../assets/tomyumoumkon.png'
import Cookies from 'universal-cookie';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useRef, useEffect, useState } from 'react';


type Props = {}
const Text36PX = styled(Typography)(({ theme }) => ({
  color: "#436336",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '24px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '26px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '30px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '34px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '36px'
  },
}));
const Text22PX = styled(Typography)(({ theme }) => ({
  color: "#1E4722",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '18px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '22px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '22px'
  },
}));
const Text16PX = styled(Typography)(({ theme }) => ({
  color: "#787F2F",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '12px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '13px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '16px'
  },
}));
const Cards = styled(Grid)(({ theme }) => ({
  borderRadius: "24px",
  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
  padding: "12px 0",
  [theme.breakpoints.up("xs")]: {
    width: "200px",

  },
  [theme.breakpoints.up("sm")]: {
    width: "250px",

  },
  [theme.breakpoints.up("md")]: {
    width: "300px",

  },
  [theme.breakpoints.up("lg")]: {
    width: "350px",
  }
}));
const Productscontainer = styled(Grid)(({ theme }) => ({
  width: '95%',
  maxWidth: "1440px",
  margin: "24px auto",
  justifyContent:"space-around",
  rowGap:"32px"
}));
const IMG = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: "130px",

  },
  [theme.breakpoints.up("sm")]: {
    height: "150px",

  },
  [theme.breakpoints.up("md")]: {
    height: "200px",

  },
  [theme.breakpoints.up("lg")]: {
    height: "250px",
  }
}));
const ProductRicecontainer = styled(Grid)(({ theme }) => ({
  width: '95%',
  maxWidth: "1440px",
  margin: "24px auto",
  justifyContent: "space-between",
  rowGap:"34px"

}));
const CardsProdRice = styled(Grid)(({ theme }) => ({
  borderRadius: "24px",
  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
  padding: "12px 0",
  [theme.breakpoints.up("xs")]: {
    width: "150px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "250px",

  },
  [theme.breakpoints.up("md")]: {
    width: "300px",

  },
  [theme.breakpoints.up("lg")]: {
    width: "330px",
  },
  [theme.breakpoints.up("xl")]: {
    width: "380px",
  },
  margin:"0 auto"
}));
const IMGRice = styled("img")(({ theme }) => ({
  margin: "0 auto",
  [theme.breakpoints.up("xs")]: {
    height: "150px",
  },
  [theme.breakpoints.up("sm")]: {
    height: "300px",
  },
  [theme.breakpoints.up("md")]: {
    height: "350px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "400px",
  },
  [theme.breakpoints.up("xl")]: {
    height: "450px",
  },
}));
const Aboutcontainer = styled(Grid)(({ theme }) => ({
  Width: '90%',
  maxWidth: "1440px",
  margin: "50px auto",
  position: "relative",
}));
const AboutRow = styled(Grid)(({ theme }) => ({
  width: "100%",
  // flexWrap: "wrap-reverse",
  justifyContent: "space-between",
  // flexWrap: "nowrap",
  [theme.breakpoints.up("xs")]: {
    flexWrap: "wrap-reverse",
    gap: "24px"
  },
  [theme.breakpoints.up("sm")]: {
    flexWrap: "nowrap",
    gap: "0px"

  },

}));
const AboutColImg = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  [theme.breakpoints.up("xs")]: {
    width: "90%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "40%",
  },
  margin: "45px 0"

}));
const AboutColcontent = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "90%",
    margin: "0 auto"
  },
  [theme.breakpoints.up("sm")]: {
    width: "59%",
  },

}));
const IMGAbout = styled("img")(({ theme }) => ({
  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
  [theme.breakpoints.up("xs")]: {
    width: "150px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "200px",
  },
  [theme.breakpoints.up("md")]: {
    width: "240px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "270x",
  },
  [theme.breakpoints.up("xl")]: {
    width: "300px",
  },
}));
const Contactcontainer = styled(Grid)(({ theme }) => ({
  Width: '90%',
  maxWidth: "1440px",
  margin: "50px auto",
  position: "relative",
  justifyContent: "center",
  flexDirection: "column",

}));
const Logo = styled("img")(({ theme }) => ({
  width: "280px",
}));
function Shop({ }: Props) {

  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();

  const [products, setProducts] = useState<any>([]);
  const [productsRice, setProductsRice] = useState<any>([]);

  useEffect(() => {

    getProducts();
    getProductsRice();

  }, [])

  const getProducts = () => {
      axios.get(`${process.env.REACT_APP_API_URL}/products/all/1`).then((res) => {
        setProducts(res.data);
      })
  }

  const getProductsRice = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/products/all/2`).then((res) => {
      setProductsRice(res.data);
    })
  }

  const prodImg = [
    {
      img: slide1
    },
    {
      img: greensub
    },
    {
      img: kasub
    },
    {
      img: gangpanang
    },
    {
      img: kasub
    },
    {
      img: gangpanang
    },
  ]
  const prodImgRice = [
    {
      img: rice
    },
    {
      img: rice2
    },
    {
      img: rice3
    },
  ]
  return (
    <>
      <SliderImg />
      <Text36PX textAlign={"center"} margin={"12px 0"}>
          สินค้าของเรา
        </Text36PX>
      <Productscontainer container>
        {
          products.map((prod:any) => (
            <Cards container>
              <IMG src={prod.path.replace('{api_path}', process.env.REACT_APP_API_URL)} sx={{ margin: "0 auto" }} />
              <Grid container flexDirection={"column"}>
                <Grid container justifyContent={"space-around"}>
                  <Text22PX>
                  {prod.name}
                  </Text22PX>
                  <Text22PX>
                  {prod.price}
                  </Text22PX>
                </Grid>
                <Grid container justifyContent={"space-around"}>
                  <Text16PX>
                    {prod.name_eng} <br />
                    <Text16PX sx={{ color: "#B0B0B0" }}>
                    {prod.product_price_name} / Inner box
                    </Text16PX>
                  </Text16PX>
                  <IconButton>
                    <ShoppingCartIcon onClick={()=>navigate("/productdetail/"+prod.id)} sx={{ fontSize: "32px", color: "#787F2F" }} />

                  </IconButton>
                </Grid>
              </Grid>
            </Cards>

          ))
        }
      </Productscontainer>
      <Text36PX textAlign={"center"} margin={"12px 0"}>
        สินค้าของเรา
      </Text36PX>
      <ProductRicecontainer container>
        {
          productsRice.map((prod:any) => (
            <CardsProdRice container>
              <IMGRice src={prod.path.replace('{api_path}', process.env.REACT_APP_API_URL)} />
              <Grid container flexDirection={"column"}>
                <Grid container justifyContent={"space-around"}>
                  <Text22PX>
                  {prod.name}
                  </Text22PX>
                  <Text22PX>
                  {prod.price}
                  </Text22PX>
                </Grid>
                <Grid container justifyContent={"space-around"}>
                  <Text16PX sx={{ color: "#B0B0B0" }}>
                    <br />
                    {prod.product_price_name} / Nylon Pouch
                  </Text16PX>
                  <IconButton>
                    <ShoppingCartIcon onClick={()=>navigate("/productdetail/"+prod.id)} sx={{ fontSize: "32px", color: "#787F2F" }} />

                  </IconButton>
                </Grid>
              </Grid>
            </CardsProdRice>
          ))
        }
      </ProductRicecontainer>
      
      <Aboutcontainer>
        <AboutRow container>
          <AboutColImg container>
            <IMGAbout src={Tomyumkung} sx={{ rotate: "350deg" }} />
          </AboutColImg>
          <AboutColcontent>
            <Text36PX>ABOUT US</Text36PX>
            <Divider />
            <Text22PX>
              Afroz Solution
            </Text22PX>
            <Text16PX>
              Lorem ipsum dolor sit amet consectetur. Luctus nisl purus viverra quam nulla scelerisque. Placerat elementum amet
            </Text16PX>
          </AboutColcontent>
        </AboutRow>
        <AboutRow container>
          <AboutColImg container>
            <IMGAbout src={FoodRormmit} sx={{ rotate: "35deg" }} />
          </AboutColImg>
          <AboutColcontent>
            <Text22PX>
              A Delicious Mission
            </Text22PX>
            <Text16PX>
              Lorem ipsum dolor sit amet consectetur. Luctus nisl purus viverra quam nulla scelerisque. Placerat elementum amet
            </Text16PX>
          </AboutColcontent>
        </AboutRow>
        <AboutRow container>
          <AboutColImg container>
            <IMGAbout src={kangkewwon} sx={{ rotate: "35deg" }} />
          </AboutColImg>
          <AboutColcontent>
            <Text22PX>
              A vision to Serve The Best
            </Text22PX>
            <Text16PX>
              Lorem ipsum dolor sit amet consectetur. Luctus nisl purus viverra quam nulla scelerisque. Placerat elementum amet
            </Text16PX>
          </AboutColcontent>
        </AboutRow>
        <AboutRow container>
          <AboutColImg container>
            <IMGAbout src={tomyumoumkon} sx={{ rotate: "350deg" }} />
          </AboutColImg>
          <AboutColcontent>
            <Text22PX>
              Products that Go Beyond Quality
            </Text22PX>
            <Text16PX>
              Lorem ipsum dolor sit amet consectetur. Luctus nisl purus viverra quam nulla scelerisque. Placerat elementum amet
            </Text16PX>
          </AboutColcontent>
        </AboutRow>
      </Aboutcontainer>
      {/* ========= Contact US ========== */}
      <Contactcontainer container>
        <Container>
        <Logo src={LOGO} />
        <Text22PX>
          บริษัท อัฟรอซ จำกัด1769 ซอยพัฒนาการ37 ถนนพัฒนาการ แขวงพัฒนาการเขตสวนหลวง กรุงเทพ 10250
        </Text22PX>
        <Divider />
        <Text36PX>
          CONTACT US
        </Text36PX>
        <Grid container margin={"12px 0"} sx={{ color: "#436336" }} gap={"24px"}>
          <FacebookIcon />
          <EmailIcon />
          <InstagramIcon />
          <YouTubeIcon />
          <CallIcon />
        </Grid>
        </Container>
      </Contactcontainer>
    </>
  )
}

export default Shop