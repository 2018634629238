import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Grid, Button, FormControl, FormLabel, TextField, Box, Paper, Typography, Container } from "@mui/material";
import Cookies from 'universal-cookie';
import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import DescriptionIcon from '@mui/icons-material/Description';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { jsPDF } from 'jspdf';
import { font } from './Sarabun-Regular-normal';

const PrintBill = () => {

    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    const [data, setData] = useState<any>('');
    const [fileName, setFileName] = useState<any>('');

    useEffect(() => {
        if (cookies.get('token') == null || cookies.get('token') === '' || cookies.get('token') === undefined) {
            return navigate('/');
        }

        axios.get(`${process.env.REACT_APP_API_URL}/token/check`, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {

            if(response.data.isAdmin !== 1) {
                return navigate('/');
            } else {
                const data = cookies.get('bill_data');
                setFileName(data.firstname + '_' + data.lastname + '.pdf');
                const address = 'คุณ ' + data.firstname + ' ' + data.lastname + ' \n' + 'เบอร์โทรศัพท์ ' + data.phone + ' \n' + 'ที่อยู่ ' + data.address;

                setData(address);
            }
        })


    }, []);

    const printBill = () =>{
        
      const doc = new jsPDF();

      doc.addFileToVFS('Fonts.ttf', font);
      doc.addFont('Fonts.ttf', 'Fonts', 'normal');
      doc.setFont('Fonts');

      doc.text(data, 10, 10);
      doc.save(fileName);
    }

    return (
            <Container>
              <Paper>
              <Grid item sx={{mt: 4}}>
                <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 300 }}>
                  ที่อยู่ในการจัดส่ง
                </Typography>
                <TextField
                  id="outlined-firstName-input"
                  type="text"
                  autoComplete="current-firstName"
                  variant="outlined"
                  sx={{
                    width: '1000px',
                    borderRadius: '0px',
                  }}
                value={data}
                  />
              </Grid>
    
              <Grid item sx={{mb: 5}}>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    width: '180px',
                    height: '46px',
                    borderRadius: '23px',
                    bgcolor: '#AEC782',
                    fontSize: '16px',
                    fontWeight: 600,
                    lineHeight: '24px',
                  }}
                  onClick={() => {printBill()}}
                >
                  พิมพ์ใบปะหน้าพัสดุ
                </Button>
              </Grid>
              </Paper>
              </Container>
    
      );
}

export default PrintBill;