import SliderImg from '../latout/imgSlider/SliderImg'
import { Container, Divider, Grid, IconButton, Typography, styled } from '@mui/material';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import slide1 from '../assets/slide1sub.png'
import greensub from '../assets/greensub.png'
import kasub from '../assets/ka.png'
import gangpanang from '../assets/gangpanang.png'
import fram from '../assets/frame.png'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import rice from '../assets/rice1.png'
import rice2 from '../assets/rice2.png'
import rice3 from '../assets/rice3.png'
import katakai from '../assets/kungtad.png'
import News from '../assets/NEWS.png'
import TopAbuotUs from '../assets/aboutTop.png'
import Tomyumkung from '../assets/tomyumgung.png'
import FoodRormmit from '../assets/foodrommit.png'
import kangkewwon from '../assets/kangkewwon.png'
import tomyumoumkon from '../assets/tomyumoumkon.png'
import LOGO from '../assets/LOGOAbout.jpg'
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CallIcon from '@mui/icons-material/Call';
import Cookies from 'universal-cookie';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useRef, useEffect, useState } from 'react';

type Props = {}
const Text36PX = styled(Typography)(({ theme }) => ({
  color: "#436336",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '24px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '26px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '30px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '34px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '36px'
  },
}));
const Text22PX = styled(Typography)(({ theme }) => ({
  color: "#1E4722",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '18px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '22px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '22px'
  },
}));
const Text16PX = styled(Typography)(({ theme }) => ({
  color: "#787F2F",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: '12px'
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: '13px'
  },
  [theme.breakpoints.up("md")]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: '16px'
  },
}));

const ProductSlidecontainer = styled(Grid)(({ theme }) => ({
  Width: '95%',
  maxWidth: "1440px",
  margin: "24px auto",
  [theme.breakpoints.up("xs")]: {
    height: "300px",
  },
  [theme.breakpoints.up("sm")]: {
    height: "350px",
  },
  [theme.breakpoints.up("md")]: {
    height: "380px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "400px",
  },
}));
const Cards = styled(Grid)(({ theme }) => ({
  borderRadius: "24px",
  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
  padding: "12px 0",
  [theme.breakpoints.up("xs")]: {
    width: "200px",

  },
  [theme.breakpoints.up("sm")]: {
    width: "250px",

  },
  [theme.breakpoints.up("md")]: {
    width: "300px",

  },
  [theme.breakpoints.up("lg")]: {
    width: "350px",
  }
}));
const IMG = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: "130px",

  },
  [theme.breakpoints.up("sm")]: {
    height: "150px",

  },
  [theme.breakpoints.up("md")]: {
    height: "200px",

  },
  [theme.breakpoints.up("lg")]: {
    height: "250px",
  }
}));
const ProductRicecontainer = styled(Grid)(({ theme }) => ({
  Width: '95%',
  maxWidth: "1440px",
  margin: "24px auto",

  [theme.breakpoints.up("xs")]: {
    height: "340px",
  },
  [theme.breakpoints.up("sm")]: {
    height: "440px",
  },
  [theme.breakpoints.up("md")]: {
    height: "auto",
  },

  justifyContent: "space-around"

}));
const CardsProdRice = styled(Grid)(({ theme }) => ({
  borderRadius: "24px",
  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
  padding: "12px 0",
  [theme.breakpoints.up("xs")]: {
    width: "150px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "250px",

  },
  [theme.breakpoints.up("md")]: {
    width: "300px",

  },
  [theme.breakpoints.up("lg")]: {
    width: "330px",
  },
  [theme.breakpoints.up("xl")]: {
    width: "380px",
  }
}));
const IMGRice = styled("img")(({ theme }) => ({
  margin: "0 auto",
  [theme.breakpoints.up("xs")]: {
    height: "150px",
  },
  [theme.breakpoints.up("sm")]: {
    height: "300px",
  },
  [theme.breakpoints.up("md")]: {
    height: "350px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "400px",
  },
  [theme.breakpoints.up("xl")]: {
    height: "450px",
  },
}));
const ProductDetailcontainer = styled(Grid)(({ theme }) => ({
  Width: '90%',
  maxWidth: "1440px",
  margin: "50px auto",
  justifyContent: "space-around",
  overflow: "hidden",
  [theme.breakpoints.up("xs")]: {
    display: "none"

  },
  [theme.breakpoints.up("md")]: {
    display: "flex"
  }


}));
const ProductDetailListcontainer = styled(Grid)(({ theme }) => ({
  Width: '90%',
  maxWidth: "1440px",
  margin: "50px auto",
  position: "relative",
}));
const BG = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: "64px",
  clipPath: "polygon(0 0, 100% 35%, 100% 100%, 0% 100%)",
  backgroundColor: "#E3F485",
  position: "absolute",
  top: "0",
  zIndex: "-1",
}));
const CardsProdList = styled(Grid)(({ theme }) => ({
  borderRadius: "24px",
  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
  padding: "12px 0",
  [theme.breakpoints.up("xs")]: {
    width: "200px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "250px",

  },
  [theme.breakpoints.up("md")]: {
    width: "290px",

  },
  [theme.breakpoints.up("lg")]: {
    width: "350px",
  },
  zIndex: "1",
  backgroundColor: "#FFF",
  margin: "0 auto"
}));
const Newscontainer = styled(Grid)(({ theme }) => ({
  Width: '90%',
  maxWidth: "1440px",
  margin: "50px auto",
  '& div p': {
    margin: "12px 24px"
  }
}));
const IMGNEWS = styled("img")(({ theme }) => ({
  margin: "0 auto",
  borderRadius: "20px",
  width: "25%"
  // [theme.breakpoints.up("xs")]: {
  //   height: "260px",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   height: "280px",
  // },
  // [theme.breakpoints.up("md")]: {
  //   height: "300px",
  // },
  // [theme.breakpoints.up("lg")]: {
  //   height: "320px",
  // },
  // [theme.breakpoints.up("xl")]: {
  //   height: "450px",
  // },
}));
const IMGTopAboutUs = styled("img")(({ theme }) => ({
  width: "400px",
}));
const Aboutcontainer = styled(Grid)(({ theme }) => ({
  Width: '90%',
  maxWidth: "1440px",
  margin: "50px auto",
  position: "relative",
}));
const AboutRow = styled(Grid)(({ theme }) => ({
  width: "100%",
  // flexWrap: "wrap-reverse",
  justifyContent: "space-between",
  // flexWrap: "nowrap",
  [theme.breakpoints.up("xs")]: {
    flexWrap: "wrap-reverse",
    gap: "24px"
  },
  [theme.breakpoints.up("sm")]: {
    flexWrap: "nowrap",
    gap: "0px"

  },

}));
const AboutColImg = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  [theme.breakpoints.up("xs")]: {
    width: "90%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "40%",
  },
  margin: "45px 0"

}));
const AboutColcontent = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "90%",
    margin: "0 auto"
  },
  [theme.breakpoints.up("sm")]: {
    width: "59%",
  },

}));
const IMGAbout = styled("img")(({ theme }) => ({
  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
  [theme.breakpoints.up("xs")]: {
    width: "150px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "200px",
  },
  [theme.breakpoints.up("md")]: {
    width: "240px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "270x",
  },
  [theme.breakpoints.up("xl")]: {
    width: "300px",
  },
}));
const Contactcontainer = styled(Grid)(({ theme }) => ({
  Width: '90%',
  maxWidth: "1440px",
  margin: "50px auto",
  position: "relative",
  justifyContent: "center",
  flexDirection: "column",

}));
const Logo = styled("img")(({ theme }) => ({
  // [theme.breakpoints.up("xs")]: {
  //   width: "150px",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   width: "200px",
  // },
  // [theme.breakpoints.up("md")]: {
  //   width: "240px",
  // },
  // [theme.breakpoints.up("lg")]: {
  //   width: "270x",
  // },
  // [theme.breakpoints.up("xl")]: {
  //   width: "300px",
  // },
  width: "280px"
}));
function Home({ }: Props) {

  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();

  const [idNew, setIdNew] = useState<any>(null);
  const [imgNew, setImgNew] = useState<any>(null);
  const [nameNew, setNameNew] = useState<any>(null);
  const [nameEngNew, setNameEngNew] = useState<any>(null);
  const [priceNameNew, setPriceNameNew] = useState<any>(null);
  const [descriptionNew, setDescriptionNew] = useState<any>(null);

  const [products, setProducts] = useState<any>([]);
  const [productsRice, setProductsRice] = useState<any>([]);

  useEffect(() => {

    getProducts();
    getProductsRice();

  }, [])

  const getProducts = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/products/all/1`).then((res) => {

      setIdNew(res.data[0].id);
      setImgNew(res.data[0].path);
      setNameNew(res.data[0].name);
      setNameEngNew(res.data[0].name_eng);
      setPriceNameNew(res.data[0].product_price_name);
      setDescriptionNew(res.data[0].description);

      setProducts(res.data);
    })
}

  const getProductsRice = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/products/all/2`).then((res) => {
      setProductsRice(res.data);
    })
  }

  const prodImg = [
    {
      img: slide1
    },
    {
      img: greensub
    },
    {
      img: kasub
    },
    {
      img: gangpanang
    },
  ]
  const prodImgRice = [
    {
      img: rice
    },
    {
      img: rice2
    },
    {
      img: rice3
    },
  ]
  return (
    <div>
      <SliderImg />
      <Text36PX textAlign={"center"} margin={"12px 0"}>
        สินค้าของเรา
      </Text36PX>
      {/* ========= product ========== */}
      <ProductSlidecontainer>
        <Swiper
          style={{ height: "100%" }}
          className="sample-slider"
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 3,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          speed={3000}
          grabCursor={false}
          breakpoints={{
            0: {
              slidesPerView: 1.5,
            },
            600: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            }
          }}
        >
          {
            products.map((prod:any) => (
              <SwiperSlide>
                <Cards container>
                  <IMG src={prod.path.replace('{api_path}', process.env.REACT_APP_API_URL)} sx={{ margin: "0 auto" }} />
                  <Grid container flexDirection={"column"}>
                    <Grid container justifyContent={"space-around"}>
                      <Text22PX>
                        {prod.name}
                      </Text22PX>
                      <Text22PX>
                        {prod.price}
                      </Text22PX>
                    </Grid>
                    <Grid container justifyContent={"space-around"}>
                      <Text16PX>
                        {prod.name_eng} <br />
                        <Text16PX sx={{ color: "#B0B0B0" }}>
                          {prod.product_price_name} / Inner box
                        </Text16PX>
                      </Text16PX>
                      <IconButton>
                        <ShoppingCartIcon onClick={()=>navigate("/productdetail/"+prod.id)} sx={{ fontSize: "32px", color: "#787F2F" }} />

                      </IconButton>
                    </Grid>
                  </Grid>
                </Cards>
              </SwiperSlide>

            ))
          }
        </Swiper>
      </ProductSlidecontainer>
      <Grid container>
        <img src={fram} style={{ width: "100%" }} />
      </Grid>
      <Text36PX textAlign={"center"} margin={"12px 0"}>
        สินค้าของเรา
      </Text36PX>
      {/* ========= rice product desktop ========== */}

      <ProductRicecontainer container sx={{ display: { xs: "none", md: "flex" } }}>
        {
          productsRice.map((prod:any) => (
            <CardsProdRice container>
              <IMGRice src={prod.path.replace('{api_path}', process.env.REACT_APP_API_URL)} />
              <Grid container flexDirection={"column"}>
                <Grid container justifyContent={"space-around"}>
                  <Text22PX>
                    {prod.name}
                  </Text22PX>
                  <Text22PX>
                    {prod.price}
                  </Text22PX>
                </Grid>
                <Grid container justifyContent={"space-around"}>
                  <Text16PX sx={{ color: "#B0B0B0" }}>
                    <br />
                    {prod.product_price_name} / Nylon Pouch
                  </Text16PX>
                  <IconButton>
                    <ShoppingCartIcon onClick={()=>navigate("/productdetail/"+prod.id)} sx={{ fontSize: "32px", color: "#787F2F" }} />

                  </IconButton>
                </Grid>
              </Grid>
            </CardsProdRice>
          ))
        }
      </ProductRicecontainer>

      {/* ========= rice product mobile ========== */}
      <ProductRicecontainer sx={{ display: { xs: "block", md: "none" } }}>
        <Swiper
          style={{ height: "100%" }}
          className="sample-slider"
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 3,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          speed={3000}
          grabCursor={false}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            600: {
              slidesPerView: 2,
            },
          }}
        >
          {
            products.map((prod:any) => (
              <SwiperSlide>
                <CardsProdRice container>
                  <IMGRice src={prod.path.replace('{api_path}', process.env.REACT_APP_API_URL)} />
                  <Grid container flexDirection={"column"}>
                    <Grid container justifyContent={"space-around"}>
                      <Text22PX>
                        {prod.name}
                      </Text22PX>
                      <Text22PX>
                        {prod.price}
                      </Text22PX>
                    </Grid>
                    <Grid container justifyContent={"space-around"}>
                      <Text16PX sx={{ color: "#B0B0B0" }}>
                        <br />
                        {prod.product_price_name} / Nylon Pouch
                      </Text16PX>
                      <IconButton>
                        <ShoppingCartIcon onClick={()=>navigate("/productdetail/"+prod.id)} sx={{ fontSize: "32px", color: "#787F2F" }} />

                      </IconButton>
                    </Grid>
                  </Grid>
                </CardsProdRice>
              </SwiperSlide>

            ))
          }

        </Swiper>
      </ProductRicecontainer>
      {/* ========= product detail ========== */}

      <ProductDetailcontainer flexDirection={"row"} container alignItems={"center"}>
        <Grid sx={{ rotate: "30deg", width: "10%", height: "90%" }}>
          <img src={imgNew !== undefined && imgNew != null && imgNew.replace('{api_path}', process.env.REACT_APP_API_URL)} style={{ width: "100%" }} />
        </Grid>
        <Grid flexDirection={"column"} sx={{ width: "270px" }} container gap={"12px"}>
          <Text36PX>
            {nameNew}
          </Text36PX>
          <Text22PX sx={{ color: "#7FA470" }}>
            {nameEngNew}
          </Text22PX>
          <Text22PX>
          {priceNameNew} / Inner box
          </Text22PX>
          <Text22PX sx={{ color: '#7A7979' }}>
          {descriptionNew !== undefined && descriptionNew != null && (descriptionNew).substring(0, 20)}
          </Text22PX>
          <Grid container gap={"24px"} alignItems={"center"}>
            <IconButton>
              <ShoppingCartIcon onClick={()=>navigate("/productdetail/"+idNew)} sx={{ color: "#787F2F", fontSize: "50px" }} />
            </IconButton>
            <Text22PX>
              สั่งซื้อสินค้า
            </Text22PX>
          </Grid>
        </Grid>
        <Grid sx={{ rotate: "280deg", width: "20%", height: "90%" }}>
          <img src={imgNew !== undefined && imgNew != null && imgNew.replace('{api_path}', process.env.REACT_APP_API_URL)} style={{ width: "100%" }} />
        </Grid>
      </ProductDetailcontainer>

      {/* ========= product detail list ========== */}
      <ProductDetailListcontainer>
        <BG></BG>
        <Swiper
          style={{ height: "100%", padding: "24px" }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            600: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            }
          }}
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          loop={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper"
        >
          {
            products.map((prod:any) => (
              <SwiperSlide>
                <CardsProdList container>
                  <IMG src={prod.path.replace('{api_path}', process.env.REACT_APP_API_URL)} sx={{ margin: "0 auto" }} />
                  <Grid container flexDirection={"column"}>
                    <Grid container justifyContent={"space-around"}>
                      <Text22PX>
                        {prod.name}
                      </Text22PX>
                      <Text22PX>
                        {prod.price}
                      </Text22PX>
                    </Grid>
                    <Grid container justifyContent={"space-around"}>
                      <Text16PX>
                      {(prod.description).substring(0, 20)} <br />
                        <Text16PX sx={{ color: "#B0B0B0" }}>
                        {prod.product_price_name} / Inner box
                        </Text16PX>
                      </Text16PX>
                      <IconButton>
                        <ShoppingCartIcon onClick={()=>navigate("/productdetail/"+prod.id)} sx={{ fontSize: "32px", color: "#787F2F" }} />

                      </IconButton>
                    </Grid>
                  </Grid>
                </CardsProdList>
              </SwiperSlide>
            ))
          }
        </Swiper>

      </ProductDetailListcontainer>
      {/* ========= NEWS ========== */}
      <Newscontainer>
        <Grid>
          <Text36PX>NEWS</Text36PX>
        </Grid>
        <Grid container justifyContent={"space-between"}>
          {/* ========= NEWS DeskTop ========== */}
          {
            prodImgRice.map(id => (
              <>
                <IMGNEWS src={News} sx={{ display: { xs: "none", sm: "flex" } }} />
              </>
            ))
          }
          {/* ========= NEWS mobile ========== */}
          <Grid container sx={{ display: { xs: "block", sm: "none" } }}>
            <Swiper
              cssMode={true}
              navigation={true}
              pagination={true}
              mousewheel={true}
              keyboard={true}
              loop={true}
              slidesPerView={1}
              modules={[Navigation, Pagination, Mousewheel, Keyboard]}
              className="mySwiper"
            >
              {
                prodImgRice.map(id => (
                  <SwiperSlide>
                    <IMGNEWS src={News} sx={{ width: "90%", display: { xs: "block", sm: "none" } }} />
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </Grid>
          <Grid container>
            <Text36PX>Lorem ipsum dolo</Text36PX>
            <Text22PX>Lorem ipsum dolor sit amet consectetur. Egestas ipsum egestas rhoncus sagittis et duis morbi rutrum. Venenatis platea sit semper sit eget. Enim ipsum turpis faucibus est mattis.Lorem ipsum dolor sit amet consectetur. Egestas ipsum egestas rhoncus sagittis et duis morbi rutrum. Venenatis platea sit semper sit eget. Enim ipsum turpis faucibus est mattis.Lorem ipsum dolor sit amet consectetur. Egestas ipsum egestas rhoncus sagittis et duis morbi rutrum. Venenatis platea sit semper sit eget. Enim ipsum turpis faucibus est mattis.</Text22PX>
          </Grid>
        </Grid>
      </Newscontainer>
      {/* ========= About US ========== */}
      <Grid container justifyContent={"end"} sx={{ display: { xs: "none", sm: "flex" } }}>
        <IMGTopAboutUs src={TopAbuotUs} />
      </Grid>
      <Aboutcontainer>
        <AboutRow container>
          <AboutColImg container>
            <IMGAbout src={Tomyumkung} sx={{ rotate: "350deg" }} />
          </AboutColImg>
          <AboutColcontent>
            <Text36PX>ABOUT US</Text36PX>
            <Divider />
            <Text22PX>
              Afroz Solution
            </Text22PX>
            <Text16PX>
              Lorem ipsum dolor sit amet consectetur. Luctus nisl purus viverra quam nulla scelerisque. Placerat elementum amet
            </Text16PX>
          </AboutColcontent>
        </AboutRow>
        <AboutRow container>
          <AboutColImg container>
            <IMGAbout src={FoodRormmit} sx={{ rotate: "35deg" }} />
          </AboutColImg>
          <AboutColcontent>
            <Text22PX>
              A Delicious Mission
            </Text22PX>
            <Text16PX>
              Lorem ipsum dolor sit amet consectetur. Luctus nisl purus viverra quam nulla scelerisque. Placerat elementum amet
            </Text16PX>
          </AboutColcontent>
        </AboutRow>
        <AboutRow container>
          <AboutColImg container>
            <IMGAbout src={kangkewwon} sx={{ rotate: "35deg" }} />
          </AboutColImg>
          <AboutColcontent>
            <Text22PX>
              A vision to Serve The Best
            </Text22PX>
            <Text16PX>
              Lorem ipsum dolor sit amet consectetur. Luctus nisl purus viverra quam nulla scelerisque. Placerat elementum amet
            </Text16PX>
          </AboutColcontent>
        </AboutRow>
        <AboutRow container>
          <AboutColImg container>
            <IMGAbout src={tomyumoumkon} sx={{ rotate: "350deg" }} />
          </AboutColImg>
          <AboutColcontent>
            <Text22PX>
              Products that Go Beyond Quality
            </Text22PX>
            <Text16PX>
              Lorem ipsum dolor sit amet consectetur. Luctus nisl purus viverra quam nulla scelerisque. Placerat elementum amet
            </Text16PX>
          </AboutColcontent>
        </AboutRow>
      </Aboutcontainer>
      {/* ========= Contact US ========== */}
      <Contactcontainer container>
        <Container>
          <Logo src={LOGO} />
          <Text22PX>
            บริษัท อัฟรอซ จำกัด1769 ซอยพัฒนาการ37 ถนนพัฒนาการ แขวงพัฒนาการเขตสวนหลวง กรุงเทพ 10250
          </Text22PX>
          <Divider />
          <Text36PX>
            CONTACT US
          </Text36PX>
          <Grid container margin={"12px 0"} sx={{ color: "#436336" }} gap={"24px"}>
            <FacebookIcon />
            <EmailIcon />
            <InstagramIcon />
            <YouTubeIcon />
            <CallIcon />
          </Grid>
        </Container>
      </Contactcontainer>
    </div>
  )
}

export default Home