import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Home from './pages/Home';
import Shop from './pages/Shop';
import Cart from './pages/Cart';
import News from './pages/News';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import ProductDetail from './pages/ProductDetail';
import BuySucceed from './pages/BuySucceed';
import Login from './pages/Login';
import Register from './pages/register';
import Reset from './pages/reset';
import ResetPassword from './pages/resetpassword';
import Admin from './pages/admin/Admin';
import AdminPage from './pages/admin/AdminPage';
import AdminLogin from './pages/admin/AdminLogin';
import EditProduct from './pages/admin/EditProduct';
import EditProfile from './pages/EditProfile';
import PrintBill from './pages/admin/PrintBill';


let Theme = createTheme({
  palette: {
    primary: {
      main: '#AEC782',
    },

  },
  typography: {
    fontFamily: [
      "Kanit", "sans-serif"
    ].join(','),
  },
})

const router = createBrowserRouter([
  // { path: '/login', element: < /> },
  {
    element: <App />, children: [
      { path: '/', element: <Home /> },
      { path: '/shop', element: <Shop /> },
      { path: '/cart', element: <Cart /> },
      { path: '/news', element: <News /> },
      { path: '/aboutus', element: <AboutUs /> },
      { path: '/contactus', element: <ContactUs /> },
      { path: '/productdetail/:id', element: <ProductDetail /> },
      { path: '/succeed', element: <BuySucceed /> },
      { path: '/edit', element: <EditProfile /> },

      { path: '/login', element: <Login /> },
      { path: '/register', element: <Register /> },
      { path: '/reset', element: <Reset /> },
      { path: '/resetpassword/:params', element: <ResetPassword /> },
      { path: '/admin', element: <Admin /> },
      { path: '/adminPage', element: <AdminPage /> },
      { path: '/adminLogin', element: <AdminLogin /> },
      { path: '/editproduct/:id', element: <EditProduct /> },
      { path: '/printBill', element: <PrintBill /> },

    ]
  }
])



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
