import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { useLocation, Outlet } from 'react-router-dom';
import Navbar from './latout/navbar/Navbar';
import Footer from './latout/footer/Footer';

function App() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])


  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>

  );
}

export default App;
