import * as React from 'react';
import { Paper, Typography, TextField, Button, Grid, Box, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import logo from '../assets/login/Ellipse 24.png';
import circle from '../assets/login/Ellipse 24.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom';
import { useRef, useEffect, useState  } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

function ResetPassword() {

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const newPasswordRef = useRef<any>(null);
  const newConfirmPasswordRef = useRef<any>(null);
  const notify = (msg:any, options:any) => toast(msg, {...options});
  const navigate = useNavigate();

  let { params } = useParams();

  const Resetpassword = async () => {

    const newPassword = newPasswordRef.current.value;
    const newConfirmPassword = newConfirmPasswordRef.current.value;

    if (newPassword != null && newPassword !== '' && newPassword !== undefined && newConfirmPassword != null && newConfirmPassword !== '' && newConfirmPassword !== undefined) {

      if(newPassword !== newConfirmPassword) {
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: 'รหัสผ่านใหม่ไม่ตรงกัน กรุณาตรวจสอบความถูกต้อง',
          icon: "warning"
      })
      return;
    } 

      const sendData = {
        new_password: newPassword,
        data: params
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/members/reset/password/new`, sendData);

      if (response.data.message === 'Success') {
          Swal.fire({
            title: "แก้ไขรหัสผ่านสำเร็จ",
            text: response.data.message,
            icon: "success"
        }).then(() => {
          return navigate('/');
        })
      } else {
          Swal.fire({
            title: "แก้ไขรหัสผ่านไม่สำเร็จ",
            text: response.data.message,
            icon: "error"
        })
      }

    } else {
        notify('กรุณากรอกข้อมูลให้ครบ', {
          duration: 5000,
          style: {
              color: "white",
              backgroundColor: "red"
          },
          icon: '❌',
          position: 'bottom-center',
          iconTheme: {
              primary: '#000',
              secondary: '#fff',
          },
      });
    }

  }


  return (
    <Grid
      container
      sx={{

        p: { xs: 1, md: 10 },
        position: 'relative',
        background: '#FFFFFF',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: { xs: 'auto', md: 'hidden' }
      }}
    >
    <Toaster />
      <Grid item md={6} textAlign={'center'} sx={{ p: { xs: 5, md: 5 } }}>
        <img src={logo} alt="Logo" />
      </Grid>
      <Grid item md={6} textAlign={'left'} sx={{ p: { xs: 1, md: 5 } }}  style={{ zIndex: 9999 }}>
        <Paper elevation={2} sx={{ p: { xs: 3, md: 8 }, borderRadius: 4 }}>
          <Typography variant='h4' sx={{ color: '#094a2a', fontWeight: '600' }}>
            ตั้งค่ารหัสผ่านใหม่
          </Typography>
          <br></br>
          <Typography variant='body1'>
            กรุณากรอกรหัสผ่านใหม่ <br /> 
          </Typography>
          <br></br>
          <Typography variant='body1' sx={{ color: '#949CA9' }}>
            กรอกรหัสผ่านใหม่
          </Typography>
          <TextField
            id="outlined-lastName-input"
            type="password"
            autoComplete="current-lastName"
            variant="outlined"
            fullWidth
            sx={{
              borderRadius: '4px',
            }}
            inputRef={newPasswordRef}
          />
          <br></br>
          <br></br>
          <Typography variant='body1' sx={{ color: '#949CA9' }}>
            กรอกรหัสผ่านใหม่อีกครั้ง
          </Typography>

          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
              
            }}
            type={showPassword ? 'text' : 'password'}
            id="outlined-lastName-input"
            autoComplete="current-lastName"
            variant="outlined"
            fullWidth
            sx={{
              borderRadius: '4px',
            }}
            inputRef={newConfirmPasswordRef}
          />
          <br></br>
          <br></br>
          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              height: '50px',
              borderRadius: '10px',
              bgcolor: '#AEC782',
            }}
            onClick={() => Resetpassword()}
          >
            บันทึก
          </Button>

        </Paper>


      </Grid>
      <Box sx={{ position: 'absolute', left: '-10%', top: '-10%' }}>
        <img src={circle} alt="" />
      </Box>
      <Box sx={{ position: 'absolute', right: '-10%', bottom: '-10%' }}>
        <img src={circle} alt="" />
      </Box>
    </Grid>

  );
}

export default ResetPassword;
