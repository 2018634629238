import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Grid, Button, FormControl, FormLabel, TextField, Box } from "@mui/material";
import Cookies from 'universal-cookie';
import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import DescriptionIcon from '@mui/icons-material/Description';

const Bill = () => {

    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();

    const [categoriesRef, setCategoriesRef] = useState<any>([]);
    const nameRef = useRef<any>(null);

    const columns: GridColDef[] = [
        { field: 'firstname', headerName: 'ชื่อ', flex: 1 },
        { field: 'lastname', headerName: 'นามสกุล', flex: 1 },
        { field: 'phone', headerName: 'เบอร์โทรศัพท์', flex: 1 },
        { field: 'address', headerName: 'ที่อยู่การจัดส่ง', flex: 1 },
        { field: 'status_payment', headerName: 'สถานะการชำระเงิน', flex: 1 },
        {
            field: 'print', headerName: 'พิมพ์ใบปะหน้าพัสดุ', flex: 1, renderCell: (r) => {
                return (<Button variant={'contained'} color="success" size={'small'} onClick={() => {
                    printBill(r.row);
                }}><DescriptionIcon fontSize="small"></DescriptionIcon></Button>)
            }
        },
        {
            field: 'action', headerName: 'ลบรายการ', flex: 1, renderCell: (r) => {
                return (<Button variant={'contained'} color="error" size={'small'} onClick={() => {
                    deleteCategories(r.row.id);
                }}><DeleteOutline fontSize="small"></DeleteOutline></Button>)
            }
        },
    ];

    const getCategories = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/orders/getBill`, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            const data = response.data;

            setCategoriesRef(data.bill);
        })
    }

    const printBill = async (data:any) => {

        cookies.set('bill_data', data);
        return navigate('/printBill');

    }

    const deleteCategories = async (id:any) => {
    
        if(id != null && id !== '' && id !== undefined) {
    
            Swal.fire({
                title: "ยืนยันการลบรายการนี้ ?",
                text: "แน่ใจที่จะลบรายการนี้ใช่หรือไม่",
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "ใช่, ลบเลย!",
                cancelButtonText: "ไม่, ยกเลิก!",
            }).then((status) => {
                if (status.isConfirmed) {
                    axios.post(`${process.env.REACT_APP_API_URL}/orders/delete`, {
                        id: id
                    }, {
                        headers: {
                            token: cookies.get('token')
                        }
                    }).then((response) => {
                        if (response.data.message === 'OK') {
                            Swal.fire({
                                title: "ลบรายการเรียบร้อยแล้ว",
                                icon: "success",
                            }).then(() => {
                                window.location.reload();
                            })
                        }
                        else {
                            Swal.fire({
                                title: "เกิดข้อผิดพลาด",
                                text: `ลบรายการไม่สำเร็จ (${response.data.message})`,
                                icon: "error",
                            })
                        }
                    })
                }
            })
    
        } else {
            const notify = (msg:any, options:any) => toast(msg, {...options});
            notify('เลือกรายการที่จะลบ', {
                duration: 5000,
                style: {
                    color: "white",
                    backgroundColor: "red"
                },
                icon: '❌',
                position: 'bottom-center',
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
            });
        }
        
    }

    useEffect(() => {
        if (cookies.get('token') == null || cookies.get('token') === '' || cookies.get('token') === undefined) {
            return navigate('/');
        }

        axios.get(`${process.env.REACT_APP_API_URL}/token/check`, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {

            if(response.data.isAdmin !== 1) {
                return navigate('/');
            }
        })
        getCategories();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                <Toaster />

                </Grid>

                <Grid item xs={12} md={12} >

                    <Box sx={{ width: { xs: 200, sm: 200, md: '100%' } }} style={{ overflow: 'auto' }} >
                        <DataGrid
                            sx={{ width: { xs: 400, sm: 400, md: '100%' } }}
                            rows={categoriesRef}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pageSizeOptions={[10, 20, 50, 100]}
                            rowSelection={false}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Bill;