import * as React from 'react';
import { Paper, Typography, TextField, Button, Grid } from '@mui/material';
import logo from '../assets/LOGOAbout.jpg';
import circle from '../assets/login/Ellipse 24.png';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

function EditProfile() {

  const notify = (msg:any, options:any) => toast(msg, {...options});
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();

  const firstnameRef = useRef<any>(null);
  const lastnameRef = useRef<any>(null);
  const phoneRef = useRef<any>(null);
  const addressRef = useRef<any>(null);

  const editProfile = async () => {

    const firstname = firstnameRef.current.value;
    const lastname = lastnameRef.current.value;
    const phone = phoneRef.current.value;
    const address = addressRef.current.value;

    if(firstname != null && firstname !== '' && firstname !== undefined 
      && lastname != null && lastname !== '' && lastname !== undefined 
      && phone != null && phone !== '' && phone !== undefined
      && address != null && address !== '' && address !== undefined) {
        
      const sendData = {
        firstname: firstname,
        lastname: lastname,
        phoneNumber: phone,
        address: address
      }
      
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/members/changeProfile/`, sendData, {
        headers: {
          token: cookies.get('token')
      }
      });

      if(response.data.message === 'success') {

        cookies.set('data', {
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            address: address
        });

        notify('แก้ไขข้อมูลสำเร็จ', {
          duration: 3000,
          style: {
              color: "white",
              backgroundColor: "green"
          },
          icon: '✔',
          position: 'bottom-center',
          iconTheme: {
              primary: '#000',
              secondary: '#fff',
          },
      });

      setTimeout(() => {
        return navigate('/');
      }, 5000);

      } else {
          notify('เกิดข้อผิดพลาด แก้ไขข้อมูลไม่สำเร็จ', {
            duration: 5000,
            style: {
                color: "white",
                backgroundColor: "red"
            },
            icon: '❌',
            position: 'bottom-center',
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
        });
      }

    } else {
        notify('กรุณากรอกข้อมูลให้ครบ', {
          duration: 5000,
          style: {
              color: "white",
              backgroundColor: "red"
          },
          icon: '❌',
          position: 'bottom-center',
          iconTheme: {
              primary: '#000',
              secondary: '#fff',
          },
      });
    }
  }

  React.useEffect(() => {

    if (cookies.get('token') != null && cookies.get('token') !== '' && cookies.get('token') !== undefined) {
      const user_data = cookies.get('data');
      firstnameRef.current.value = user_data.firstname;
      lastnameRef.current.value = user_data.lastname;
      phoneRef.current.value = user_data.phone;
      addressRef.current.value = user_data.address;
    } else {
        return navigate('/login');
    }

  }, [])

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        backgroundColor: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
      }}
    >
      <Toaster />
      <Grid item xs={12} sm={6} md={6} lg={6}
        container
        justifyContent="center"
        alignItems="center"
        sx={{ maxWidth: '100%' }}
      >
        <Grid item sx={{ mt: { xs: 5, sm: 0, md: 0, lg: 0 } }}>
          <img src={logo} alt="Logo" style={{ width: '280px', height: '101px' }} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Paper
            elevation={3}
            sx={{
              width: { sx: 'auto', sm: 500, md: 500, lg: 500 },
              height: { sx: 'auto', sm: 600, md: 600, lg: 600 },
              borderRadius: '20px',
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 19px 40px 0px #000000D',
              ml: { xs: 0, sm: -17, md: -15, lg: 10 },
              mt: { xs: 10, sm: 10, md: 15, lg: 2 },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontSize: '40px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0em', mt: 7 }}>
              แก้ไขข้อมูลส่วนตัว
            </Typography>

          <Grid item sx={{mt: 4}}>
            <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 300 }}>
              ชื่อ
            </Typography>
            <TextField
              id="outlined-firstName-input"
              type="text"
              autoComplete="current-firstName"
              variant="outlined"
              sx={{
                width: '360px',
                borderRadius: '4px',
              }}
            inputRef={firstnameRef}
              />
          </Grid>

          <Grid item sx={{mt: 2}}>
            <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 300 }}>
              นามสกุล
            </Typography>
            <TextField
              id="outlined-lastName-input"
              type="text"
              autoComplete="current-lastName"
              variant="outlined"
              sx={{
                width: '360px',
                borderRadius: '4px',
              }}
            inputRef={lastnameRef}
            />
          </Grid>

          <Grid item sx={{mt: 2}}>
            <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 300 }}>
              เบอร์โทรศัพท์
            </Typography>
            <TextField
              id="outlined-Phone-input"
              type="tel"
              autoComplete="current-Phone"
              variant="outlined"
              sx={{
                width: '360px',
                borderRadius: '4px',
              }}
              inputRef={phoneRef}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>

          <Grid item sx={{mt: 2}}>
            <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 300 }}>
              ที่อยู่ในการจัดส่ง
            </Typography>
            <TextField
              id="outlined-lastName-input"
              type="text"
              autoComplete="current-lastName"
              variant="outlined"
              sx={{
                width: '360px',
                borderRadius: '4px',
              }}
            inputRef={addressRef}
            />
          </Grid>
      
          <Grid item sx={{mb: 5}}>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                width: '156px',
                height: '46px',
                borderRadius: '23px',
                bgcolor: '#AEC782',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
              }}
              onClick={() => {editProfile()}}
            >
              บันทึก
            </Button>
          </Grid>
          </Paper>
        </Grid>
      </Grid>

       <Grid container sx={{ mt: { sx: 0, sm: -50, md: -80, lg: -40 }, ml: { sx: 0, sm: 55, md: 75, lg: 140 } }}>
        <img src={circle} alt="" style={{ maxWidth: '338px', maxHeight: '338px' }} />
      </Grid>
    </Grid>
    
  );
}

export default EditProfile;
