import React from 'react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { Grid, Typography, styled } from '@mui/material';
import slider1 from '../../assets/slide1.png';
import slidersub1 from '../../assets/slide1sub.png';
import green from '../../assets/green.png'
import greensub from '../../assets/greensub.png'

type Props = {}
const SliderComponent = styled(Grid)(({ theme }) => ({
    position: "relative",
}));
const BoxLeft = styled(Grid)(({ theme }) => ({
    zIndex: "1",
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
    [theme.breakpoints.up("md")]: {
        width: "50%",

    }

}));
/* clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%); */
const BoxRight = styled(Grid)(({ theme }) => ({
    zIndex: "5",
    backgroundColor: "#FFF",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",

    [theme.breakpoints.down("md")]: {
        width: "100%",
        top: "500px",
        height: "40%",
    },
    [theme.breakpoints.up("md")]: {
        left: "40%",
        width: "60%",
        clipPath: "polygon(10% 0, 100% 0, 100% 100%, 0% 100%)",

    }

}));
const BoxCenter = styled(Grid)(({ theme }) => ({
    backgroundColor: "#FFF",
    zIndex: "6",
    position: "absolute",
    borderRadius: "12px",
    display: "flex",
    border: "3px solid #000",
    [theme.breakpoints.up("xs")]: {
        height: "24 0px",
        left: "70%",
        top: "50%",
        transform: 'translate(-50%, -50%)',
        width: "40%"
    },
    [theme.breakpoints.up("sm")]: {
        height: "280px",
        left: "70%",
        top: "50%",
        transform: 'translate(-50%, -50%)',
        width: "30%"
    },
    [theme.breakpoints.up("md")]: {
        height: "330px",
        top: "50%",
        left: "45%",
        transform: 'translate(-50%, -50%)',
        width: "auto"

    }
}));
const IMG = styled("img")(({ theme }) => ({
    width: "100%",
    height: "500px",
    objectFit: "cover"
}));
const IMGcenter = styled("img")(({ theme }) => ({
    width: "80%",
    margin: "0 auto"
}));
const Text36PX = styled(Typography)(({ theme }) => ({
    color: "#436336",
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: '24px'
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: '26px'
    },
    [theme.breakpoints.up("md")]: {
        fontSize: '30px'
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: '34px'
    },
    [theme.breakpoints.up("xl")]: {
        fontSize: '36px'
    },
}));
const Text24PX = styled(Typography)(({ theme }) => ({
    color: "#7FA470",
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: '16px'
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: '18px'
    },
    [theme.breakpoints.up("md")]: {
        fontSize: '20px'
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: '24px'
    },
    [theme.breakpoints.up("xl")]: {
        fontSize: '24px'
    },
}));
const Text22PX = styled(Typography)(({ theme }) => ({
    color: "#7A7979",
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: '14px'
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: '16px'
    },
    [theme.breakpoints.up("md")]: {
        fontSize: '18px'
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: '22px'
    },
    [theme.breakpoints.up("xl")]: {
        fontSize: '22px'
    },
}));
function SliderImg({ }: Props) {
    const sllider = [
        {
            img: slider1,
            sub: slidersub1
        },
        {
            img: green,
            sub: greensub
        },
        {
            img: slider1,
            sub: slidersub1
        },
    ]
    return (
        <>
            <Swiper
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                loop={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
            >
                {
                    sllider.map(imgs => (
                        <SwiperSlide>
                            <SliderComponent container>
                                <BoxLeft>
                                    <IMG src={imgs.img} />
                                </BoxLeft>
                                <BoxRight container>
                                    <Grid width={{ xs: "100%", md: "50%" }} marginLeft={"45px"}>
                                        <Text36PX>
                                            น้ำแกงแดง
                                        </Text36PX>
                                        <Text24PX>
                                            RED CURRY SAUCE
                                        </Text24PX>
                                        <Text22PX>
                                            Lorem ipsum dolor sit amet consectetur. Egestas ipsum egestas rhoncus sagittis et duis morbi rutrum. Venenatis platea sit semper sit eget. Enim ipsum turpis faucibus est mattis.
                                        </Text22PX>
                                    </Grid>
                                </BoxRight>
                                <BoxCenter>
                                    <IMGcenter src={imgs.sub} />
                                </BoxCenter>
                            </SliderComponent>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </>
    )
}

export default SliderImg