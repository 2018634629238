import { AccountCircle, CloudUploadOutlined, SearchOutlined } from "@mui/icons-material"
import { TabContext, TabPanel } from "@mui/lab";
import { Grid, Box, Button, Card, CardActionArea, CardContent, CardMedia, Divider, FormControl, FormLabel, IconButton, Input, InputAdornment, InputBase, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Paper, Stack, Tab, Tabs, TextField, Typography } from "@mui/material"
import { alpha, styled } from '@mui/material/styles';
import AddProduct from "./AddProduct";
import Category from "./Category";
import Banner from "./Banner";
import ConfigWebsite from "./ConfigWebsite";
import Bill from "./Bill";
import Cookies from 'universal-cookie';
import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Swal from 'sweetalert2'

const listOfMenus = [ {
    name: 'สินค้าทั้งหมด',
    path: 'Product'
}, {
    name: 'เพิ่มสินค้าใหม่',
    path: 'AddProduct'
}, {
    name: 'หมวดหมู่ทั้งหมด',
    path: 'Category'
}, {
    name: 'ตั้งค่า Banner',
    path: 'Banner'
}, {
    name: 'ตั้งค่า Website',
    path: 'Website'
},{
    name: 'พิมพ์ใบปะหน้าพัสดุ',
    path: 'Bill'
}]

const AdminPage = () => {

    const [tab, setTab] = React.useState<string>("Product");
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();

    const [productRef, setProductRef] = useState<any>([]);

    useEffect(() => {
        if (cookies.get('token') == null || cookies.get('token') === '' || cookies.get('token') === undefined) {
            return navigate('/adminLogin');
        }

        axios.get(`${process.env.REACT_APP_API_URL}/token/check`, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {

            if(response.data.isAdmin !== 1) {
                return navigate('/');
            }
        })
        getProducts();
    }, []);

    const getProducts = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/products/all`).then((response) => {
            const data = response.data;

            setProductRef(data);
        })
    }

    const deleteProduct = (product_id:any) => {

        axios.post(`${process.env.REACT_APP_API_URL}/products/deleteProduct`, {
            product_id : product_id
        }, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {

            if(response.data.message === 'OK') {
                Swal.fire({
                    title: "ลบสินค้าสำเร็จ",
                    icon: "success",
                }).then(() => {
                    window.location.reload();
                })
            } else {
                Swal.fire({
                    title: "ลบสินค้าไม่สำเร็จ",
                    text: response.data.message,
                    icon: "error",
                })
            }
        })
    }

    return (
        <Box p={3}>
            <Paper elevation={2}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4} p={2}>

                        <Paper elevation={2} sx={{}}>
                            <List
                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                subheader={
                                    <ListSubheader style={{ background: '#069a02', color: 'white' }} component="div" id="nested-list-subheader"
                                    >
                                        เมนู
                                    </ListSubheader>
                                }
                            >
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={tab}
                                    onChange={(event: React.SyntheticEvent, newValue: string) => {
                                        setTab(newValue)
                                    }}
                                    aria-label="Vertical tabs example"
                                    sx={{ borderRight: 1, borderColor: 'divider' }}
                                >
                                    {
                                        listOfMenus.map((item, index) => {
                                            return <Tab key={index} value={item.path} label={item.name}
                                                sx={{
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'flex-start', // Align label to the start
                                                    textAlign: 'left', // Align text to the left
                                                    minWidth: 'auto', // Allow tab to adjust width based on content
                                                }}
                                            />
                                        })
                                    }
                                </Tabs>


                            </List>
                        </Paper>


                    </Grid>
                    <Grid item md={8} lg={8} p={2}>
                        <TabContext value={tab}>
                            <TabPanel value={"Product"} >

                                <Grid container p={2} spacing={2}>
                                    {
                                        productRef.map((item:any, index:any) => {
                                            return (
                                                <Grid item key={index} xs={12} sm={6} md={3}>
                                                    <Card>
                                                        
                                                        <CardActionArea>
                                                            <CardMedia
                                                                component="img"
                                                                style={{
                                                                    minWidth: '100%'
                                                                }}
                                                                image={item.path !== undefined && item.path != null && item.path.replace('{api_path}', process.env.REACT_APP_API_URL)}
                                                                alt="green iguana"
                                                            />
                                                            <CardContent>

                                                                <Typography variant={'body1'} color="text.secondary" textAlign={'center'}>
                                                                    {item.name}
                                                                </Typography>
                                                                <Typography variant={'body2'} color="text.secondary" textAlign={'center'}>
                                                                    {item.price}
                                                                </Typography>
                                                            </CardContent>

                                                            <Button onClick={() => navigate('/editproduct/'+item.id)}>
                                                                <p>แก้ไขสินค้า</p>&nbsp;
                                                                <EditNoteIcon></EditNoteIcon>
                                                            </Button>

                                                            <Button onClick={() => deleteProduct(item.id)}>
                                                                <p>ลบสินค้า</p>&nbsp;
                                                                <DeleteForeverIcon></DeleteForeverIcon>
                                                            </Button>
                                                            
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            )
                                        })
                                    }

                                </Grid>
                            </TabPanel>


                            <TabPanel value={'AddProduct'}>
                                <AddProduct></AddProduct>

                            </TabPanel>

                            <TabPanel value={'Category'}>
                                <Category></Category>

                            </TabPanel>

                            <TabPanel value={'Banner'}>
                                <Banner></Banner>

                            </TabPanel>

                            <TabPanel value={'Website'}>
                                <ConfigWebsite></ConfigWebsite>

                            </TabPanel>

                            <TabPanel value={'Bill'}>
                                <Bill></Bill>

                            </TabPanel>
                        </TabContext>



                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}
export default AdminPage