import AdminPage from "./AdminPage";
import AdminLogin from "./AdminLogin";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
    const context: any = useOutletContext();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();

    useEffect(() => {

        if (cookies.get('token') != null && cookies.get('token') !== '' && cookies.get('token') !== undefined) {

            axios.get(`${process.env.REACT_APP_API_URL}/token/check`, {
                headers: {
                    token: cookies.get('token')
                }
            }).then((res) => {

                if(res.data.isAdmin == 1) {
                    return navigate('/adminPage');
                } else {
                    return navigate('/adminLogin');
                }
    
            })
        } else {
            return navigate('/adminLogin');
        }
    }, [])

    return (
        <>
        </>
    )
}

export default Admin;